import React, { Suspense, lazy } from 'react'

import { Router, Route, Switch } from 'react-router'

import { Layout, PageLoader, SubHeader } from 'components'

import { history } from 'helpers/history'

import {
  HOME_ROUTE,
  SEND_ROUTE,
  UPGRADE_RUNE_ROUTE,
  SWAP_ROUTE,
  AFFILIATE_ROUTE,
} from 'settings/constants'

export type Routes = {
  exact?: boolean
  path?: string | string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any
  background?: boolean
}[]

const routes: Routes = [
  {
    exact: true,
    path: HOME_ROUTE,
    component: lazy(() => import('views/Home')),
  },
  {
    exact: true,
    path: `${SEND_ROUTE}/:asset`,
    component: lazy(() => import('views/Send')),
  },
  {
    exact: true,
    path: `${AFFILIATE_ROUTE}`,
    component: lazy(() => import('views/Affiliate')),
  },
  {
    exact: true,
    path: `${UPGRADE_RUNE_ROUTE}`,
    component: lazy(() => import('views/Upgrade')),
  },
  {
    exact: true,
    path: `${SWAP_ROUTE}/:pair`,
    component: lazy(() => import('views/Swap')),
  },
]

const PublicRoutes = () => (
  <Router history={history}>
    <Suspense fallback={<PageLoader />}>
      <Switch>
        {routes.map((route, index) => {
          const Component = route.component
          const { background = true } = route

          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Layout transparent={!background}>
                  <SubHeader />
                  <Component {...props} />
                </Layout>
              )}
            />
          )
        })}
      </Switch>
    </Suspense>
  </Router>
)

export default PublicRoutes
