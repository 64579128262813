import {
  MsgNativeTx,
  ThorchainDepositResponse,
} from '@xchainjs/xchain-thorchain'
import axios, { AxiosResponse } from 'axios'
import { StdTx } from 'cosmos-client/x/auth'

import { midgardAPI, thornodeAPI } from './config'
import { ThornodeNetwork, InboundAddressesItem } from './types'

export const getInboundData = (
  network: ThornodeNetwork,
): Promise<AxiosResponse<InboundAddressesItem[]>> => {
  return axios.get(midgardAPI('v2/thorchain/inbound_addresses', network))
}

// https://docs.thorchain.org/how-it-works/governance#mimir
export const getThorchainMimir = (network: ThornodeNetwork) => {
  return axios.get(thornodeAPI('mimir', network))
}

export const buildDepositTx = async (
  msgNativeTx: MsgNativeTx,
): Promise<StdTx> => {
  try {
    const response: ThorchainDepositResponse = await axios
      .post(thornodeAPI('deposit', 'mainnet'), {
        coins: msgNativeTx.coins,
        memo: msgNativeTx.memo,
        base_req: {
          chain_id: 'thorchain',
          from: msgNativeTx.signer,
        },
      })
      .then((response) => response.data)

    if (!response || !response.value) {
      throw new Error('Invalid client url')
    }

    const unsignedStdTx = StdTx.fromJSON({
      msg: response.value.msg,
      fee: response.value.fee,
      signatures: [],
      memo: '',
    })

    return unsignedStdTx
  } catch (error) {
    return Promise.reject(new Error('Invalid client url'))
  }
}
