import { Network } from '@xchainjs/xchain-client'

import { getTradeLimitWithIdentifier } from '../utils/memo'
import { Amount } from './amount'
import { Asset } from './asset'
import { Percent } from './percent'

export class Memo {
  /**
   * Cannot be constructed.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getShortenedSymbol = (asset: Asset): string => {
    const { symbol, chain } = asset

    let shortenedSymbol = symbol
    // use shortened asset name for ERC20 tokens
    if (chain === 'ETH' && !asset.isETH()) {
      shortenedSymbol = `${asset.ticker}-${asset.symbol.slice(-3)}`
    }

    return shortenedSymbol
  }

  public static swapMemo(
    asset: Asset,
    address = '',
    limit?: Amount,
    network?: Network,
    srcAsset?: Asset,
    affiliates?: string[] | null,
  ) {
    const { chain } = asset
    const limitString = getTradeLimitWithIdentifier(limit)
    const shortenedSymbol = Memo.getShortenedSymbol(asset)
    const AFFILIATE_POINTS = 100
    if (asset.isSynth) {
      return `=:${chain}/${shortenedSymbol}:${address}:${limitString}`
    }
    // BTC and LTC op_return too small for memo
    if (srcAsset && srcAsset?.chain !== 'BTC' && srcAsset?.chain !== 'LTC') {
      return `=:${chain}.${shortenedSymbol}:${address}:${limitString}:${Memo.checkAffiliate(
        affiliates,
        network,
      )}:${AFFILIATE_POINTS}`
    }

    return `=:${chain}.${shortenedSymbol}:${address}:${limitString}`
  }

  public static checkAffiliate(
    affiliates?: string[] | null,
    network?: string,
    ran?: number,
  ) {
    // also need to change other one.
    const PROPORTION = 0.8
    if (!ran) {
      ran = Math.random()
    }
    const DEFAULT_AFFILIATE_ADDRESS =
      network === 'testnet'
        ? 'tthor1ygue0vwzlcuydw0s40t3mf7kjsvlcsr2dxwkkh'
        : 'thor1ygue0vwzlcuydw0s40t3mf7kjsvlcsr2f3lx0j'
    // check if valid address!
    if (!affiliates || affiliates.length < 1) {
      return DEFAULT_AFFILIATE_ADDRESS
    }
    return ran > PROPORTION
      ? DEFAULT_AFFILIATE_ADDRESS
      : Memo.affiliateLogic(affiliates, PROPORTION, ran)
  }

  public static affiliateLogic(
    affiliateList: string[],
    proportion: number,
    ran: number,
  ) {
    for (let i = 0; i < affiliateList.length - 1; i++) {
      proportion *= 0.8
      if (ran > proportion) {
        return affiliateList[i]
      }
    }
    return affiliateList[affiliateList.length - 1]
  }

  public static depositMemo(asset: Asset, address = '') {
    const { chain, symbol } = asset

    return `+:${chain}.${symbol}:${address}`
  }

  public static withdrawMemo(
    asset: Asset,
    percent: Percent,
    targetAsset?: Asset,
  ) {
    const { chain } = asset

    const target = targetAsset ? `:${targetAsset.toString()}` : ''
    const shortenedSymbol = Memo.getShortenedSymbol(asset)

    // multiply percent by 100
    return `-:${chain}.${shortenedSymbol}:${percent
      .mul(100)
      .assetAmount.toNumber()}${target}`
  }

  public static upgradeMemo(address: string) {
    return `SWITCH:${address}`
  }
}
