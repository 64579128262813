import { EtherscanProvider } from '@ethersproject/providers'
import { getDecimal } from '@xchainjs/xchain-ethereum'
import {
  BNBChain,
  BTCChain,
  THORChain,
  ETHChain,
  LTCChain,
  Chain,
  AssetBNB,
  AssetBTC,
  AssetETH,
  AssetLTC,
  AssetBCH,
  AssetRuneNative,
  BCHChain,
} from '@xchainjs/xchain-util'

import { ETHERSCAN_API_KEY, NETWORK_TYPE } from 'multichain-sdk/config'

import { getAssetType, getAssetName, getNetworkName } from '../constants/chains'
import {
  DEFAULT_CHAIN_DECIMAL,
  THORCHAIN_DECIMAL,
  BNB_DECIMAL,
  BTC_DECIMAL,
  ETH_DECIMAL,
  LTC_DECIMAL,
  BCH_DECIMAL,
} from '../constants/decimals'

export type AssetNetwork = 'mainnet' | 'testnet'

export type Protocol = 'thorchain' | 'anyswap' | 'chainflip' | 'sifchain'

export type AssetObj = {
  chain: Chain
  symbol: string
  ticker: string
}

export interface IAsset {
  readonly protocols: Protocol[]
  readonly chain: Chain
  readonly symbol: string
  readonly ticker: string
  readonly type: string
  readonly name: string
  readonly network: string

  decimal: number

  isSynth: boolean

  // TODO: add asset icon url

  getAssetObj(): AssetObj
  toString(): string
  currencySymbol(): string
  eq(asset: Asset): boolean
  isRUNE(): boolean
  isBNB(): boolean
  sortsBefore(asset: Asset): number
}

/**
 * L1 asset format:
 * - CHAIN.SYMBOL (Raw string, URL)
 * Synth asset format: CHAIN/SYMBOL
 * - CHAIN/SYMBOL (Raw string)
 * - THOR.CHAIN.SYMBOL (URL)
 */

export class Asset implements IAsset {
  public readonly chain: Chain

  public readonly symbol: string

  public readonly ticker: string

  public readonly type: string

  public readonly network: string

  public readonly name: string

  public readonly protocols: Protocol[]

  public decimal: number

  public isSynth = false

  // created for USD pricing
  public static USD(): Asset {
    return new Asset(THORChain, 'USD-USD')
  }

  public static BNB(): Asset {
    return new Asset(AssetBNB.chain, AssetBNB.symbol)
  }

  public static RUNE(): Asset {
    return new Asset(AssetRuneNative.chain, AssetRuneNative.symbol)
  }

  public static BNB_RUNE(): Asset {
    return new Asset(AssetBNB.chain, AssetRuneNative.symbol)
  }

  public static ETH_RUNE(): Asset {
    return new Asset(AssetRuneNative.chain, AssetRuneNative.symbol)
  }

  public static BTC(): Asset {
    return new Asset(AssetBTC.chain, AssetBTC.symbol)
  }

  public static ETH(): Asset {
    return new Asset(AssetETH.chain, AssetETH.symbol)
  }

  public static LTC(): Asset {
    return new Asset(AssetLTC.chain, AssetLTC.symbol)
  }

  public static BCH(): Asset {
    return new Asset(AssetBCH.chain, AssetBCH.symbol)
  }

  public static fromAssetString(asset: string): Asset | null {
    let chain: Chain
    let symbol: string
    let isSynth: boolean

    // check if synth or not
    if (asset.includes('/')) {
      chain = asset.split('/')[0] as Chain
      symbol = asset.split('/')[1]

      isSynth = true
    } else {
      chain = asset.split('.')[0] as Chain
      symbol = asset.split('.')[1]

      isSynth = false
    }

    const ticker = symbol.split('-')?.[0]

    if (chain && symbol && ticker) {
      return new Asset(chain, symbol, isSynth)
    }

    return null
  }

  /**
   *
   * @param urlEncodedAsset asset string from url
   * @returns btc.btc -> btc.btc, thor.btc.btc -> btc/btc
   */
  public static decodeFromURL = (urlEncodedAsset: string): Asset | null => {
    let assetString = urlEncodedAsset.toUpperCase()

    if (
      assetString.startsWith('THOR.') &&
      assetString.split('THOR.')?.[1] !== 'RUNE'
    ) {
      // synth asset
      assetString = assetString.split('THOR.')?.[1]?.replace('.', '/')
    }

    return Asset.fromAssetString(assetString)
  }

  public static async getDecimalByAsset(asset: Asset): Promise<number> {
    const { chain, symbol, ticker, isSynth } = asset

    if (isSynth) return THORCHAIN_DECIMAL

    if (chain === BNBChain) return BNB_DECIMAL
    if (chain === BTCChain) return BTC_DECIMAL
    if (chain === THORChain) return THORCHAIN_DECIMAL
    if (chain === LTCChain) return LTC_DECIMAL
    if (chain === BCHChain) return BCH_DECIMAL

    if (chain === ETHChain) {
      if (symbol === 'ETH' && ticker === 'ETH') {
        return ETH_DECIMAL
      }

      const provider = new EtherscanProvider(NETWORK_TYPE, ETHERSCAN_API_KEY)
      const decimal = await getDecimal(asset.getAssetObj(), provider)

      return decimal
    }

    return DEFAULT_CHAIN_DECIMAL
  }

  public static getDecimalByChain(chain: Chain): number {
    if (chain === BNBChain) return BNB_DECIMAL
    if (chain === BTCChain) return BTC_DECIMAL
    if (chain === THORChain) return THORCHAIN_DECIMAL
    if (chain === LTCChain) return LTC_DECIMAL
    if (chain === BCHChain) return BCH_DECIMAL

    if (chain === ETHChain) {
      return ETH_DECIMAL
    }

    return DEFAULT_CHAIN_DECIMAL
  }

  constructor(chain: Chain, symbol: string, isSynth = false) {
    this.chain = chain
    this.symbol = symbol
    this.ticker = Asset.getTicker(symbol)
    this.type = getAssetType(chain, this.ticker, isSynth)
    this.name = getAssetName(chain, this.ticker, isSynth)
    this.network = getNetworkName(chain, this.ticker)
    this.protocols = getSupportedProtocols(chain, this.ticker)
    this.decimal = isSynth ? THORCHAIN_DECIMAL : Asset.getDecimalByChain(chain)

    this.isSynth = isSynth
  }

  /**
   * THORChain for Synth assets
   * L1 chain for non-synth assets
   */
  get L1Chain(): Chain {
    if (this.isSynth) return THORChain

    return this.chain
  }

  public setDecimal = async (decimal?: number) => {
    if (decimal !== undefined) {
      this.decimal = decimal
    } else {
      this.decimal = await Asset.getDecimalByAsset(this)
    }
  }

  public static getTicker(symbol: string): string {
    return symbol.split('-')[0]
  }

  /**
   * @returns get asset object to be used for xchainjs
   */
  public getAssetObj(): AssetObj {
    // synth format: THOR.btc/btc (NOTE: lowercase notation)
    if (this.isSynth) {
      const synthSymbol = `${this.chain.toLowerCase()}/${this.symbol.toLowerCase()}`

      return {
        chain: THORChain as Chain,
        symbol: synthSymbol,
        ticker: synthSymbol,
      }
    }

    // L1 format: BTC.BTC
    return { chain: this.chain, symbol: this.symbol, ticker: this.ticker }
  }

  /**
   * convert asset entity to string
   * @returns L1 asset -> btc.btc, Synth asset -> btc/btc
   */
  toString(): string {
    if (!this.isSynth) {
      return `${this.chain}.${this.symbol}`
    }

    return `${this.chain}/${this.symbol}`
  }

  toURLEncoded(): string {
    if (!this.isSynth) {
      return `${this.chain}.${this.symbol}`
    }

    return `THOR.${this.chain}.${this.symbol}`
  }

  currencySymbol(): string {
    return this.ticker
    // return currencySymbolByAsset(this.getAssetObj())
  }

  // full compare chain, symbol, synth
  eq(asset: Asset): boolean {
    return (
      this.chain === asset.chain &&
      this.symbol.toUpperCase() === asset.symbol.toUpperCase() &&
      this.ticker.toUpperCase() === asset.ticker.toUpperCase() &&
      this.isSynth === asset.isSynth
      // this.decimal === asset.decimal
    )
  }

  // compare chain, symbol but not synth
  shallowEq(asset: Asset): boolean {
    return (
      this.chain === asset.chain &&
      this.symbol.toUpperCase() === asset.symbol.toUpperCase() &&
      this.ticker.toUpperCase() === asset.ticker.toUpperCase()
    )
  }

  isGasAsset = (): boolean => {
    return (
      this.eq(Asset.RUNE()) ||
      this.eq(Asset.ETH()) ||
      this.eq(Asset.BTC()) ||
      this.eq(Asset.BNB()) ||
      this.eq(Asset.BCH()) ||
      this.eq(Asset.LTC())
    )
  }

  isRUNE(): boolean {
    return this.eq(Asset.RUNE())
  }

  isLTC(): boolean {
    return this.eq(Asset.LTC())
  }

  isBTC(): boolean {
    return this.eq(Asset.BTC())
  }

  isBNB(): boolean {
    return this.eq(Asset.BNB())
  }

  isETH(): boolean {
    return this.eq(Asset.ETH())
  }

  sortsBefore(asset: Asset): number {
    if (this.eq(asset)) return 0

    if (this.isSynth) return 1

    if (this.chain !== asset.chain) {
      if (this.chain < asset.chain) return -1
      if (this.chain > asset.chain) return 1
    }

    if (this.symbol < asset.symbol) return -1
    if (this.symbol > asset.symbol) return 1

    return 1
  }
}
function getSupportedProtocols(chain: string, ticker: string): Protocol[] {
  if (chain && ticker) {
    return ['thorchain']
  }
  return ['thorchain']
}
