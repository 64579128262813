import styled from 'styled-components'
import { palette } from 'styled-theme'

import { media } from 'helpers/style'

export const LogoWrapper = styled.div`
  display: block;
  cursor: pointer;

  #Thorchain_logo-copy {
    > :not(:first-child) {
      fill: ${palette('text', 1)};
    }
  }

  #thorswap-logo-black,
  #thorswap-logo-white {
    width: 180px;
    height: 50px;
  }
`

export const Img = styled.img`
  width: 200px;
  ${media.sm`
    width: 20vw;
  `}
`

export const MiniImg = styled.img`
  width: 40px;
  ${media.sm`
    width: 8vw;
  `}
`
