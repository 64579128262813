export const CONFIRM_DISMISS_TIME = 2000

export const TX_PAGE_LIMIT = 20

export const TX_PUBLIC_PAGE_LIMIT = 5

export const DEFAULT_SLIPPAGE_TOLERANCE = 10

export const POLL_GAS_RATE_INTERVAL = 10 * 1000 // 10s

export const POLL_DATA_INTERVAL = 5 * 60 * 1000 // 5m
