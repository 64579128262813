import React, { useCallback, useMemo, useState } from 'react'

import { Check as ValidIcon, XOctagon as InvalidIcon } from 'react-feather'

import { EditOutlined, LockOutlined } from '@ant-design/icons'
import { Chain } from '@xchainjs/xchain-util'
import { capitalize } from 'lodash'
import { SupportedChain } from 'multichain-sdk'

import { useWallet } from 'redux/wallet/hooks'

import { multichain } from 'services/multichain'

import { truncateAddress, AddressTruncMode } from 'helpers/string'

import { CoreButton, Tooltip, WalletIcon } from '../UIElements'
import * as Styled from './AffiliateInput.style'

export type Props = {
  title: string
  chain: Chain
  chainAddr: string
  address: string
  onAddressChange: (address: string) => void
}

export const AffiliateInput: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    // AssetInput Props
    title,
    address,
    chain,
    chainAddr,
    onAddressChange,
    ...otherProps
  } = props

  const { wallet } = useWallet()
  const [isEditable, setEditable] = useState(false)
  const truncatedAddr = useMemo(
    () => (address ? truncateAddress(address, AddressTruncMode.Long) : ''),
    [address],
  )

  const isValidAddress = useMemo(
    () =>
      multichain.validateAddress({
        chain,
        address,
      }),
    [chain, address],
  )

  const handleAddressChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onAddressChange(e.target.value)
    },
    [onAddressChange],
  )

  const renderRecipientIcon = () => {
    if (!address) return <></>

    const walletType = wallet?.[chain as SupportedChain]?.walletType
    if (address === chainAddr && walletType) {
      return (
        <Tooltip placement="top" tooltip={capitalize(walletType as string)}>
          <WalletIcon size={20} walletType={walletType} />
        </Tooltip>
      )
    }

    return (
      <Styled.IconWrapper isValid={isValidAddress}>
        {isValidAddress ? <ValidIcon size={20} /> : <InvalidIcon size={20} />}
      </Styled.IconWrapper>
    )
  }

  return (
    <Styled.CardWrapper {...otherProps}>
      <Styled.CardContent>
        <Styled.AddressInput
          sizevalue="normal"
          isError={!!address && !isValidAddress}
          value={isEditable ? address : truncatedAddr}
          placeholder="Thorchain Affiliate Address Here"
          onChange={handleAddressChange}
          disabled={!isEditable}
        />
      </Styled.CardContent>
      <Styled.CardHeader>
        <Styled.CardTitle>
          <span className="card-title">{title}</span>
          {renderRecipientIcon()}
        </Styled.CardTitle>
        <Styled.ActionWrapper>
          {isEditable ? (
            <Tooltip placement="top" tooltip="Lock">
              <CoreButton onClick={() => setEditable(false)}>
                <LockOutlined />
              </CoreButton>
            </Tooltip>
          ) : (
            <Tooltip placement="top" tooltip="Edit">
              <CoreButton onClick={() => setEditable(true)}>
                <EditOutlined />
              </CoreButton>
            </Tooltip>
          )}
        </Styled.ActionWrapper>
      </Styled.CardHeader>
    </Styled.CardWrapper>
  )
}
