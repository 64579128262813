import React from 'react'

import { ThemeType } from 'theme'

import ccqLogoMini from 'assets/logo/shield2.png'
// import ccqLogoWhite from 'assets/logo/mini.png'

import { LogoWrapper, Img, MiniImg } from './Logo.style'

export type Props = {
  type: 'ccq' | 'ccqswap'
  color?: ThemeType
  mini?: boolean
}

export const Logo = (props: Props) => {
  const { mini = false, type, color = ThemeType.DARK } = props

  return (
    <LogoWrapper>
      {mini && <MiniImg src={ccqLogoMini} />}
      {!mini && type === 'ccq'}
      {!mini &&
        type === 'ccqswap' &&
        (color === ThemeType.LIGHT ? (
          <Img src={ccqLogoMini} />
        ) : (
          <Img src={ccqLogoMini} />
        ))}
    </LogoWrapper>
  )
}
