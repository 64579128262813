import { Asset } from 'multichain-sdk'

import {
  AFFILIATE_ROUTE,
  getSwapRoute,
  // TX_ROUTE,
  UPGRADE_RUNE_ROUTE,
} from 'settings/constants'

export const navMenuList = [
  {
    link: getSwapRoute(Asset.BTC(), Asset.RUNE()),
    label: 'SWAP',
    isWideOnly: false,
  },
  {
    link: AFFILIATE_ROUTE,
    label: 'AFFILIATE',
    isWideOnly: false,
  },
  {
    link: UPGRADE_RUNE_ROUTE,
    label: 'UPGRADE RUNE',
    isWideOnly: false,
  },
]
