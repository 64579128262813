import {
  BTCChain,
  BNBChain,
  THORChain,
  ETHChain,
  LTCChain,
  BCHChain,
} from '@xchainjs/xchain-util'

export const sortedChains = [
  THORChain,
  BTCChain,
  LTCChain,
  BCHChain,
  ETHChain,
  BNBChain,
]
