import styled from 'styled-components'

export const AssetFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  button {
    display: flex;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 10px;
    /* &:last-child {
      margin-right: 0px;
    } */
  }
`
