import { transparentize } from 'polished'
import styled from 'styled-components/macro'
import { palette } from 'styled-theme'

import { media } from 'helpers/style'

import { Button } from '../UIElements/Button'

export const navbar = styled.nav``

export const DesktopNavbar = styled.div`
  display: none;

  ${media.md`
    display: flex;
  `}
  align-items: center;
`
export const Container = styled.div<{ collapsed: boolean }>`
  background: transparent;
  margin-left: min(1vw, 15px);
  //margin-right: min(1vw, 15px);
  margin-bottom: 0px;
  justify-content: center;
`
export const MobileNavbar = styled.div`
  position: absolute;
  top: 5rem;
  right: 2rem;
  z-index: 999;
  min-width: 12.125rem;
  background: ${({ theme }) =>
    `${transparentize(0.2, theme.palette.background[5])}`};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);

  border: 1px solid ${palette('gray', 0)};
  border-radius: 0.75rem;
  border: none;

  display: flex;
  flex-direction: column;
  font-size: 1rem;

  a {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 50px;
    margin: 10px;
    svg {
      width: 20px;
      display: flex;
      justify-content: left;
      align-items: center;
      margin: 10px;
      stroke: none;
      color: ${palette('text', 0)};
    }
  }
`
export const Options = styled(Button)`
  margin-top: 1px;
`

export const Header = styled.div`
  display: flex;
  width: 56px;
  height: 32px;

  button {
    padding-top: 2px;

    width: 100%;
    height: 100%;
  }

  z-index: 200;
`
export const NavbarButton = styled(Button).attrs({
  typevalue: 'outline',
  round: true,
  active: false,
  focus: false,
})`
  &.ant-btn {
    display: flex;

    ${media.md`
      display: none;
    `}

    svg {
      width: 14px;
    }
  }
`
