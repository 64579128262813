import { IS_SYNTH_ACTIVE } from 'settings/config'

export type AssetFilterType = {
  type: string
  label: string
}

export const assetFilterTypes1 = [
  {
    type: 'All',
    label: 'All',
  },
  {
    type: 'Synth',
    label: 'Synth',
  },
  {
    type: 'ETH',
    label: 'ETH',
  },
  {
    type: 'BNB',
    label: 'BNB',
  },
]

// without synth
export const assetFilterTypes2 = [
  {
    type: 'All',
    label: 'All',
  },
  {
    type: 'ETH',
    label: 'ETH',
  },
  {
    type: 'BNB',
    label: 'BNB',
  },
]

export const assetFilterTypes = IS_SYNTH_ACTIVE
  ? assetFilterTypes1
  : assetFilterTypes2
