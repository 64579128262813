import React, { useEffect, useState } from 'react'

// import { TransitionGroup } from 'react-transition-group'
import '@animxyz/core'
import { XyzTransitionGroup } from '@animxyz/react'
import { Protocol } from 'multichain-sdk'

import { Tooltip } from '../UIElements/Tooltip'
import * as Styled from './ProtocolSelect.style'

import './ProtocolSelect.css'

interface ProtocolState {
  protocol: Protocol
  enabled: boolean
  color: string
}

const ProtocolDetails: { [key: string]: ProtocolState } = {
  thorchain: { protocol: 'thorchain', enabled: true, color: '23DCC888' },
  anyswap: { protocol: 'anyswap', enabled: false, color: '685ef288' },
  chainflip: { protocol: 'chainflip', enabled: false, color: 'ff3cab88' },
  sifchain: { protocol: 'sifchain', enabled: false, color: 'caa93a88' },
}

export const ProtocolSelect = () => {
  const [selectedItems] = useState<{ [key: string]: ProtocolState }>(
    ProtocolDetails,
  )

  const [show, setShow] = useState(false)
  useEffect(() => {
    setTimeout(() => setShow(true), 500)
  }, [selectedItems])
  return (
    <div>
      <div>
        <XyzTransitionGroup
          duration={2000}
          appear
          xyz="stagger-2"
          className="item-group"
        >
          {Object.entries(selectedItems).map(([key, item]) => (
            <div className="invis" key={item.protocol}>
              <Tooltip
                key={item.protocol}
                tooltip={
                  item.enabled
                    ? `${item.protocol} protocol is enabled`
                    : 'Coming Soon'
                }
                placement="topLeft"
                title="Coming Soon"
              >
                <Styled.ProtocolTag
                  key={item.protocol}
                  style={{
                    display: !show ? 'hidden' : 'block',
                    borderColor: Styled.colors[item.protocol],
                  }}
                >
                  {key}
                </Styled.ProtocolTag>
              </Tooltip>
            </div>
          ))}
        </XyzTransitionGroup>
      </div>
    </div>
  )
}
