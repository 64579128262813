import styled from 'styled-components'
import { palette } from 'styled-theme'

// import { Label } from '../../UIElements'
import { AssetInput as UnstyledAssetInput } from '../AssetInput'
import { AssetSelect as UnstyledAssetSelect } from '../AssetSelect'

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  //background-image: linear-gradient(0deg, #484848, #5a6260);
  /* border: 1px solid ${palette('gray', 0)}; */
  border-radius: 4px;
  height: 70px;
  padding-right: 4px;
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 10px;
  width: 100%;
`

export const AssetInputContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;
  margin-top: 5.5px;
  padding-right: 2px;
  padding-left: 10px;
`

export const AssetInput = styled(UnstyledAssetInput)`
  flex-grow: 1;
  margin-right: 0;
  padding: 5px;
`

export const AssetSelect = styled(UnstyledAssetSelect)`
  padding: 10px;
`

export const AssetInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100%;
  /* padding-bottom: 4px; */
`

// export const BalanceLabel = styled(Label).attrs({
//   size: 'normal',
// })`
//   font-size: 13px;
//   cursor: pointer;
//   &:hover {
//     font-weight: bold;
//   }
// `

// export const MaxBtn = styled(Button).attrs({
//   typevalue: 'outline',
//   round: true,
//   fixedWidth: false,
// })`
//   &.ant-btn {
//     width: 44px;
//     margin-bottom: 6px;
//     padding-left: 18px;
//     border-radius: 3px;
//   }
// `

export const Balance = styled.div`
  width: 100%;
  padding-right: 14px;
`

export const SliderWrapper = styled.div`
  margin: auto;
  width: 95%;
  justify-content: center;
  align-items: center;
`
