import { transparentize } from 'polished'
import styled from 'styled-components'
import { palette } from 'styled-theme'

export const ContentTitleWrapper = styled.div<{
  justifyContent: 'space-between' | 'center' | 'space-around' | 'right'
  hasPadding?: boolean
}>`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
  width: 95%;
  height: 44px;
  padding-top: 8px;

  ${(props) => props.hasPadding && 'padding: 0 20px;'}

  background-color: ${(props) =>
    transparentize(1, props.theme.palette.gray[0])};
  /* border-radius: 1px solid ${palette('gray', 1)}; */

  color: ${palette('text', 0)};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;

  border-radius: 10px;
`
