import { darken } from 'polished'

import { Palette } from './types'

export const palette: Palette = {
  primary: [
    '#45A1FF', // 50 little boy blue
    '#d6cf65', // 100 straw
    '#dcbf67', // 200 gold
    '#0f4c83', // 300 yale blue
    '#87812f', // 400 dark gold
    '#ffffff', // 500
    '#000000', // 600
    '#000000', // 700
    '#000000', // 800
    '#375BB9', // 900 honeydew
  ],
  secondary: [
    '#dff7ff', // 50
    '#ace9fe', // 100
    '#70dbfe', // 200
    '#d6cf65', // 300 secondary
    '#00c0ff', // 400
    '#00b4ff', // 500
    '#00a6f4', // 600
    '#0093e1', // 700
    '#0081cd', // 800
    '#0061ac', // 900
  ],
  gray: [
    '#f7f7f7', // 50,
    '#eeeeee', // 100,
    '#e2e2e2', // 200,
    '#d0d0d0', // 300,
    '#ababab', // 400,
    '#8a8a8a', // 500,
    '#636363', // 600,
    '#505050', // 700,
    '#323232', // 800,
    '#121212', // 900,
  ],
  dark: [
    '#ababab', // 50 light gray
    '#969696', // 100 medium grey
    '#b4b9c2', // 200
    '#969dab', // 300 medium grey
    '#a9b3be', // 400
    '#66A3A4', // 500 turquoise grey
    '#66A3A4', // 600 dark deep jungle
    '#1B4059', // 700
    '#B39939', // 800 satin sheen gold
    '#041018', // 900 night black
  ],
}

const { secondary, primary } = palette

export const DARKENED_STRAW = darken(0.35, secondary[3])
export const YGGDRASIL_GREEN = primary[3]
export const LITTLE_BOY_BLUE = '#45A1FF'
export const FLASH_ORANGE = '#999999'
export const SURTR_RED = '#FF4954'
