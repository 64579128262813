/* tslint:disable */
/* eslint-disable */
/**
 * Midgard Public API
 * The Midgard Public API queries THORChain and any chains linked via the Bifröst and prepares information about the network to be readily available for public users. The API parses transaction event data from THORChain and stores them in a time-series database to make time-dependent queries easy. Midgard does not hold critical information. To interact with BEPSwap and Asgardex, users should query THORChain directly.
 *
 * The version of the OpenAPI document: 2.2.2
 * Contact: devs@thorchain.org
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * action details among with related transactions
 * @export
 * @interface Action
 */
export interface Action {
    /**
     * Int64, nano timestamp of the block at which the action was registered
     * @type {string}
     * @memberof Action
     */
    date: string;
    /**
     * Int64, height of the block at which the action was registered
     * @type {string}
     * @memberof Action
     */
    height: string;
    /**
     * Inbound transactions related to the action
     * @type {Array<Transaction>}
     * @memberof Action
     */
    in: Array<Transaction>;
    /**
     * 
     * @type {Metadata}
     * @memberof Action
     */
    metadata: Metadata;
    /**
     * Outbound transactions related to the action
     * @type {Array<Transaction>}
     * @memberof Action
     */
    out: Array<Transaction>;
    /**
     * Pools involved in the action
     * @type {Array<string>}
     * @memberof Action
     */
    pools: Array<string>;
    /**
     * Indicates if the action is completed or if related outbound transactions are still pending.
     * @type {string}
     * @memberof Action
     */
    status: ActionStatusEnum;
    /**
     * Type of action
     * @type {string}
     * @memberof Action
     */
    type: ActionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ActionStatusEnum {
    Success = 'success',
    Pending = 'pending'
}
/**
    * @export
    * @enum {string}
    */
export enum ActionTypeEnum {
    Swap = 'swap',
    AddLiquidity = 'addLiquidity',
    Withdraw = 'withdraw',
    Donate = 'donate',
    Refund = 'refund',
    Switch = 'switch'
}

/**
 * 
 * @export
 * @interface AddLiquidityMetadata
 */
export interface AddLiquidityMetadata {
    /**
     * Int64, amount of liquidity units assigned to the member as result of the liquidity deposit
     * @type {string}
     * @memberof AddLiquidityMetadata
     */
    liquidityUnits: string;
}
/**
 * 
 * @export
 * @interface BlockRewards
 */
export interface BlockRewards {
    /**
     * 
     * @type {string}
     * @memberof BlockRewards
     */
    blockReward: string;
    /**
     * 
     * @type {string}
     * @memberof BlockRewards
     */
    bondReward: string;
    /**
     * 
     * @type {string}
     * @memberof BlockRewards
     */
    poolReward: string;
}
/**
 * 
 * @export
 * @interface BondMetrics
 */
export interface BondMetrics {
    /**
     * Int64(e8), Average bond of active nodes
     * @type {string}
     * @memberof BondMetrics
     */
    averageActiveBond: string;
    /**
     * Int64(e8), Average bond of standby nodes
     * @type {string}
     * @memberof BondMetrics
     */
    averageStandbyBond: string;
    /**
     * Int64(e8), Maxinum bond of active nodes
     * @type {string}
     * @memberof BondMetrics
     */
    maximumActiveBond: string;
    /**
     * Int64(e8), Maximum bond of standby nodes
     * @type {string}
     * @memberof BondMetrics
     */
    maximumStandbyBond: string;
    /**
     * Int64(e8), Median bond of active nodes
     * @type {string}
     * @memberof BondMetrics
     */
    medianActiveBond: string;
    /**
     * Int64(e8), Median bond of standby nodes
     * @type {string}
     * @memberof BondMetrics
     */
    medianStandbyBond: string;
    /**
     * Int64(e8), Minumum bond of active nodes
     * @type {string}
     * @memberof BondMetrics
     */
    minimumActiveBond: string;
    /**
     * Int64(e8), Minumum bond of standby nodes
     * @type {string}
     * @memberof BondMetrics
     */
    minimumStandbyBond: string;
    /**
     * Int64(e8), Total bond of active nodes
     * @type {string}
     * @memberof BondMetrics
     */
    totalActiveBond: string;
    /**
     * Int64(e8), Total bond of standby nodes
     * @type {string}
     * @memberof BondMetrics
     */
    totalStandbyBond: string;
}
/**
 * 
 * @export
 * @interface BoolConstants
 */
export interface BoolConstants {
    /**
     * 
     * @type {boolean}
     * @memberof BoolConstants
     */
    StrictBondLiquidityRatio: boolean;
}
/**
 * Represents a digital currency amount
 * @export
 * @interface Coin
 */
export interface Coin {
    /**
     * Int64(e8), asset Amount.
     * @type {string}
     * @memberof Coin
     */
    amount: string;
    /**
     * Asset in CHAIN.SYMBOL format
     * @type {string}
     * @memberof Coin
     */
    asset: string;
}
/**
 * 
 * @export
 * @interface Constants
 */
export interface Constants {
    /**
     * 
     * @type {BoolConstants}
     * @memberof Constants
     */
    bool_values: BoolConstants;
    /**
     * 
     * @type {Int64Constants}
     * @memberof Constants
     */
    int_64_values: Int64Constants;
    /**
     * 
     * @type {StringConstants}
     * @memberof Constants
     */
    string_values: StringConstants;
}
/**
 * 
 * @export
 * @interface DepthHistory
 */
export interface DepthHistory {
    /**
     * 
     * @type {Array<DepthHistoryItem>}
     * @memberof DepthHistory
     */
    intervals: Array<DepthHistoryItem>;
    /**
     * 
     * @type {DepthHistoryMeta}
     * @memberof DepthHistory
     */
    meta: DepthHistoryMeta;
}
/**
 * 
 * @export
 * @interface DepthHistoryItem
 */
export interface DepthHistoryItem {
    /**
     * Int64(e8), the amount of Asset in the pool at the end of the interval
     * @type {string}
     * @memberof DepthHistoryItem
     */
    assetDepth: string;
    /**
     * Float, price of asset in rune. I.e. rune amount / asset amount
     * @type {string}
     * @memberof DepthHistoryItem
     */
    assetPrice: string;
    /**
     * Float, the price of asset in USD (based on the deepest USD pool).
     * @type {string}
     * @memberof DepthHistoryItem
     */
    assetPriceUSD: string;
    /**
     * Int64, The end time of bucket in unix timestamp
     * @type {string}
     * @memberof DepthHistoryItem
     */
    endTime: string;
    /**
     * Int64, Liquidity Units in the pool at the end of the interval
     * @type {string}
     * @memberof DepthHistoryItem
     */
    liquidityUnits: string;
    /**
     * Int64(e8), the amount of Rune in the pool at the end of the interval
     * @type {string}
     * @memberof DepthHistoryItem
     */
    runeDepth: string;
    /**
     * Int64, The beginning time of bucket in unix timestamp
     * @type {string}
     * @memberof DepthHistoryItem
     */
    startTime: string;
    /**
     * Int64, Synth Units in the pool at the end of the interval
     * @type {string}
     * @memberof DepthHistoryItem
     */
    synthUnits: string;
    /**
     * Int64, Total Units (synthUnits + liquidityUnits) in the pool at the end of the interval
     * @type {string}
     * @memberof DepthHistoryItem
     */
    units: string;
}
/**
 * 
 * @export
 * @interface DepthHistoryMeta
 */
export interface DepthHistoryMeta {
    /**
     * Int64, The end time of bucket in unix timestamp
     * @type {string}
     * @memberof DepthHistoryMeta
     */
    endTime: string;
    /**
     * Int64, The beginning time of bucket in unix timestamp
     * @type {string}
     * @memberof DepthHistoryMeta
     */
    startTime: string;
}
/**
 * 
 * @export
 * @interface EarningsHistory
 */
export interface EarningsHistory {
    /**
     * 
     * @type {Array<EarningsHistoryItem>}
     * @memberof EarningsHistory
     */
    intervals: Array<EarningsHistoryItem>;
    /**
     * 
     * @type {EarningsHistoryItem}
     * @memberof EarningsHistory
     */
    meta: EarningsHistoryItem;
}
/**
 * 
 * @export
 * @interface EarningsHistoryItem
 */
export interface EarningsHistoryItem {
    /**
     * float64, Average amount of active nodes during the time interval
     * @type {string}
     * @memberof EarningsHistoryItem
     */
    avgNodeCount: string;
    /**
     * Int64(e8), Total block rewards emitted during the time interval
     * @type {string}
     * @memberof EarningsHistoryItem
     */
    blockRewards: string;
    /**
     * Int64(e8), Share of earnings sent to nodes during the time interval
     * @type {string}
     * @memberof EarningsHistoryItem
     */
    bondingEarnings: string;
    /**
     * Int64(e8), System income generated during the time interval. It is the sum of liquidity fees and block rewards
     * @type {string}
     * @memberof EarningsHistoryItem
     */
    earnings: string;
    /**
     * Int64, The end time of interval in unix timestamp
     * @type {string}
     * @memberof EarningsHistoryItem
     */
    endTime: string;
    /**
     * Int64(e8), Share of earnings sent to pools during the time interval
     * @type {string}
     * @memberof EarningsHistoryItem
     */
    liquidityEarnings: string;
    /**
     * Int64(e8), Total liquidity fees, converted to RUNE, collected during the time interval
     * @type {string}
     * @memberof EarningsHistoryItem
     */
    liquidityFees: string;
    /**
     * Earnings data for each pool for the time interval
     * @type {Array<EarningsHistoryItemPool>}
     * @memberof EarningsHistoryItem
     */
    pools: Array<EarningsHistoryItemPool>;
    /**
     * Float, the price of Rune based on the deepest USD pool at the end of the interval. 
     * @type {string}
     * @memberof EarningsHistoryItem
     */
    runePriceUSD: string;
    /**
     * Int64, The beginning time of interval in unix timestamp
     * @type {string}
     * @memberof EarningsHistoryItem
     */
    startTime: string;
}
/**
 * pool earnings data during the time interval
 * @export
 * @interface EarningsHistoryItemPool
 */
export interface EarningsHistoryItemPool {
    /**
     * Int64(e8), liquidity fees collected in the pool\'s asset
     * @type {string}
     * @memberof EarningsHistoryItemPool
     */
    assetLiquidityFees: string;
    /**
     * Int64(e8), total earnings in RUNE (totalLiquidityFees + rewards)
     * @type {string}
     * @memberof EarningsHistoryItemPool
     */
    earnings: string;
    /**
     * asset for the given pool
     * @type {string}
     * @memberof EarningsHistoryItemPool
     */
    pool: string;
    /**
     * Int64(e8), RUNE amount sent to (positive) or taken from (negative) the pool as a result of balancing it\'s share of system income each block 
     * @type {string}
     * @memberof EarningsHistoryItemPool
     */
    rewards: string;
    /**
     * Int64(e8), liquidity fees collected in RUNE
     * @type {string}
     * @memberof EarningsHistoryItemPool
     */
    runeLiquidityFees: string;
    /**
     * Int64(e8), total liquidity fees (assetFees + runeFees) collected, shown in RUNE
     * @type {string}
     * @memberof EarningsHistoryItemPool
     */
    totalLiquidityFeesRune: string;
}
/**
 * 
 * @export
 * @interface Health
 */
export interface Health {
    /**
     * True means healthy, connected to database
     * @type {boolean}
     * @memberof Health
     */
    database: boolean;
    /**
     * True means healthy. False means Midgard is still catching up to the chain
     * @type {boolean}
     * @memberof Health
     */
    inSync: boolean;
    /**
     * Int64, the current block count
     * @type {string}
     * @memberof Health
     */
    scannerHeight: string;
}
/**
 * 
 * @export
 * @interface InboundAddressesItem
 */
export interface InboundAddressesItem {
    /**
     * 
     * @type {string}
     * @memberof InboundAddressesItem
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof InboundAddressesItem
     */
    chain: string;
    /**
     * 
     * @type {string}
     * @memberof InboundAddressesItem
     */
    gas_rate?: string;
    /**
     * indicate whether this chain has halted
     * @type {boolean}
     * @memberof InboundAddressesItem
     */
    halted: boolean;
    /**
     * 
     * @type {string}
     * @memberof InboundAddressesItem
     */
    pub_key: string;
    /**
     * 
     * @type {string}
     * @memberof InboundAddressesItem
     */
    router?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {Array<Action>}
     * @memberof InlineResponse200
     */
    actions: Array<Action>;
    /**
     * Int64, number of results matching the given filters.
     * @type {string}
     * @memberof InlineResponse200
     */
    count: string;
}
/**
 * 
 * @export
 * @interface Int64Constants
 */
export interface Int64Constants {
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    AsgardSize: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    BadValidatorRate: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    BadValidatorRedline: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    BlocksPerYear: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    ChurnInterval: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    ChurnRetryInterval: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    DesiredValidatorSet: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    DoubleSignMaxAge: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    EmissionCurve: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    FailKeygenSlashPoints: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    FailKeysignSlashPoints: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    FullImpLossProtectionBlocks: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    FundMigrationInterval: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    IncentiveCurve: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    JailTimeKeygen: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    JailTimeKeysign: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    LackOfObservationPenalty: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    LiquidityLockUpBlocks: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    MaxAvailablePools: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    MaxSwapsPerBlock: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    MinRunePoolDepth: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    MinSlashPointsForBadValidator: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    MinSwapsPerBlock: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    MinimumBondInRune: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    MinimumNodesForBFT: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    MinimumNodesForYggdrasil: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    NativeTransactionFee: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    NewPoolCycle?: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    ObservationDelayFlexibility: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    ObserveSlashPoints: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    OldValidatorRate: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    OutboundTransactionFee: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    PoolCycle: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    SigningTransactionPeriod: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    VirtualMultSynths: number;
    /**
     * 
     * @type {number}
     * @memberof Int64Constants
     */
    YggFundLimit: number;
}
/**
 * 
 * @export
 * @interface LastblockItem
 */
export interface LastblockItem {
    /**
     * 
     * @type {string}
     * @memberof LastblockItem
     */
    chain: string;
    /**
     * 
     * @type {number}
     * @memberof LastblockItem
     */
    last_observed_in: number;
    /**
     * 
     * @type {number}
     * @memberof LastblockItem
     */
    last_signed_out: number;
    /**
     * 
     * @type {number}
     * @memberof LastblockItem
     */
    thorchain: number;
}
/**
 * 
 * @export
 * @interface LiquidityHistory
 */
export interface LiquidityHistory {
    /**
     * 
     * @type {Array<LiquidityHistoryItem>}
     * @memberof LiquidityHistory
     */
    intervals: Array<LiquidityHistoryItem>;
    /**
     * 
     * @type {LiquidityHistoryItem}
     * @memberof LiquidityHistory
     */
    meta: LiquidityHistoryItem;
}
/**
 * 
 * @export
 * @interface LiquidityHistoryItem
 */
export interface LiquidityHistoryItem {
    /**
     * Int64(e8), total assets deposited during the time interval. Denoted in Rune using the price at deposit time. 
     * @type {string}
     * @memberof LiquidityHistoryItem
     */
    addAssetLiquidityVolume: string;
    /**
     * Int64, number of deposits during the time interval. 
     * @type {string}
     * @memberof LiquidityHistoryItem
     */
    addLiquidityCount: string;
    /**
     * Int64(e8), total of rune and asset deposits. Denoted in Rune (using the price at deposit time). 
     * @type {string}
     * @memberof LiquidityHistoryItem
     */
    addLiquidityVolume: string;
    /**
     * Int64(e8), total Rune deposited during the time interval. 
     * @type {string}
     * @memberof LiquidityHistoryItem
     */
    addRuneLiquidityVolume: string;
    /**
     * Int64, The end time of bucket in unix timestamp
     * @type {string}
     * @memberof LiquidityHistoryItem
     */
    endTime: string;
    /**
     * Int64(e8), part of the withdrawRuneVolume which was payed because of impermanent loss protection. 
     * @type {string}
     * @memberof LiquidityHistoryItem
     */
    impermanentLossProtectionPaid: string;
    /**
     * Int64(e8), net liquidity changes (withdrawals - deposits) during the time interval
     * @type {string}
     * @memberof LiquidityHistoryItem
     */
    net: string;
    /**
     * Float, the price of Rune based on the deepest USD pool at the end of the interval. 
     * @type {string}
     * @memberof LiquidityHistoryItem
     */
    runePriceUSD: string;
    /**
     * Int64, The beginning time of bucket in unix timestamp
     * @type {string}
     * @memberof LiquidityHistoryItem
     */
    startTime: string;
    /**
     * Int64(e8), total assets withdrawn during the time interval. Denoted in Rune using the price at withdraw time. 
     * @type {string}
     * @memberof LiquidityHistoryItem
     */
    withdrawAssetVolume: string;
    /**
     * Int64, number of withdraw during the time interval. 
     * @type {string}
     * @memberof LiquidityHistoryItem
     */
    withdrawCount: string;
    /**
     * Int64(e8), total Rune withdrawn during the time interval. 
     * @type {string}
     * @memberof LiquidityHistoryItem
     */
    withdrawRuneVolume: string;
    /**
     * Int64(e8), total of rune and asset withdrawals. Denoted in Rune (using the price at withdraw time). 
     * @type {string}
     * @memberof LiquidityHistoryItem
     */
    withdrawVolume: string;
}
/**
 * 
 * @export
 * @interface MemberDetails
 */
export interface MemberDetails {
    /**
     * List details of all the liquidity providers identified with the given address
     * @type {Array<MemberPool>}
     * @memberof MemberDetails
     */
    pools: Array<MemberPool>;
}
/**
 * 
 * @export
 * @interface MemberPool
 */
export interface MemberPool {
    /**
     * Int64(e8), total asset added to the pool by member
     * @type {string}
     * @memberof MemberPool
     */
    assetAdded: string;
    /**
     * asset address used by the member
     * @type {string}
     * @memberof MemberPool
     */
    assetAddress: string;
    /**
     * Int64(e8), total asset withdrawn from the pool by member
     * @type {string}
     * @memberof MemberPool
     */
    assetWithdrawn: string;
    /**
     * Int64, Unix timestamp for the first time member deposited into the pool
     * @type {string}
     * @memberof MemberPool
     */
    dateFirstAdded: string;
    /**
     * Int64, Unix timestamp for the last time member deposited into the pool
     * @type {string}
     * @memberof MemberPool
     */
    dateLastAdded: string;
    /**
     * Int64, pool liquidity units that belong the the member
     * @type {string}
     * @memberof MemberPool
     */
    liquidityUnits: string;
    /**
     * Pool rest of the data refers to
     * @type {string}
     * @memberof MemberPool
     */
    pool: string;
    /**
     * Int64(e8), total RUNE added to the pool by member
     * @type {string}
     * @memberof MemberPool
     */
    runeAdded: string;
    /**
     * rune address used by the member
     * @type {string}
     * @memberof MemberPool
     */
    runeAddress: string;
    /**
     * Int64(e8), total RUNE withdrawn from the pool by member
     * @type {string}
     * @memberof MemberPool
     */
    runeWithdrawn: string;
}
/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * 
     * @type {AddLiquidityMetadata}
     * @memberof Metadata
     */
    addLiquidity?: AddLiquidityMetadata;
    /**
     * 
     * @type {RefundMetadata}
     * @memberof Metadata
     */
    refund?: RefundMetadata;
    /**
     * 
     * @type {SwapMetadata}
     * @memberof Metadata
     */
    swap?: SwapMetadata;
    /**
     * 
     * @type {WithdrawMetadata}
     * @memberof Metadata
     */
    withdraw?: WithdrawMetadata;
}
/**
 * 
 * @export
 * @interface Network
 */
export interface Network {
    /**
     * 
     * @type {Array<string>}
     * @memberof Network
     */
    activeBonds: Array<string>;
    /**
     * Int64, Number of Active Nodes
     * @type {string}
     * @memberof Network
     */
    activeNodeCount: string;
    /**
     * 
     * @type {BlockRewards}
     * @memberof Network
     */
    blockRewards: BlockRewards;
    /**
     * 
     * @type {BondMetrics}
     * @memberof Network
     */
    bondMetrics: BondMetrics;
    /**
     * Float, (1 + (bondReward * blocksPerMonth/totalActiveBond)) ^ 12 -1
     * @type {string}
     * @memberof Network
     */
    bondingAPY: string;
    /**
     * Float, (1 + (stakeReward * blocksPerMonth/totalDepth of active pools)) ^ 12 -1
     * @type {string}
     * @memberof Network
     */
    liquidityAPY: string;
    /**
     * Int64, next height of blocks
     * @type {string}
     * @memberof Network
     */
    nextChurnHeight: string;
    /**
     * Int64, the remaining time of pool activation (in blocks)
     * @type {string}
     * @memberof Network
     */
    poolActivationCountdown: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    poolShareFactor: string;
    /**
     * Array of Standby Bonds
     * @type {Array<string>}
     * @memberof Network
     */
    standbyBonds: Array<string>;
    /**
     * Int64, Number of Standby Nodes
     * @type {string}
     * @memberof Network
     */
    standbyNodeCount: string;
    /**
     * Int64(e8), Total Rune pooled in all pools
     * @type {string}
     * @memberof Network
     */
    totalPooledRune: string;
    /**
     * Int64(e8), Total left in Reserve
     * @type {string}
     * @memberof Network
     */
    totalReserve: string;
}
/**
 * 
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * ed25519 public key
     * @type {string}
     * @memberof Node
     */
    ed25519: string;
    /**
     * node thorchain address
     * @type {string}
     * @memberof Node
     */
    nodeAddress: string;
    /**
     * secp256k1 public key
     * @type {string}
     * @memberof Node
     */
    secp256k1: string;
}
/**
 * 
 * @export
 * @interface ObservedChain
 */
export interface ObservedChain {
    /**
     * 
     * @type {string}
     * @memberof ObservedChain
     */
    chain: string;
    /**
     * 
     * @type {number}
     * @memberof ObservedChain
     */
    height: number;
}
/**
 * 
 * @export
 * @interface PoolDetail
 */
export interface PoolDetail {
    /**
     * 
     * @type {string}
     * @memberof PoolDetail
     */
    asset: string;
    /**
     * Int64(e8), the amount of Asset in the pool.
     * @type {string}
     * @memberof PoolDetail
     */
    assetDepth: string;
    /**
     * Float, price of asset in rune. I.e. rune amount / asset amount.
     * @type {string}
     * @memberof PoolDetail
     */
    assetPrice: string;
    /**
     * Float, the price of asset in USD (based on the deepest USD pool).
     * @type {string}
     * @memberof PoolDetail
     */
    assetPriceUSD: string;
    /**
     * Int64, Liquidity Units in the pool.
     * @type {string}
     * @memberof PoolDetail
     */
    liquidityUnits: string;
    /**
     * Float, Average Percentage Yield: annual return estimated using last weeks income, taking compound interest into account.
     * @type {string}
     * @memberof PoolDetail
     */
    poolAPY: string;
    /**
     * Int64(e8), the amount of Rune in the pool.
     * @type {string}
     * @memberof PoolDetail
     */
    runeDepth: string;
    /**
     * The state of the pool, e.g. Available, Staged.
     * @type {string}
     * @memberof PoolDetail
     */
    status: string;
    /**
     * Int64, Synth Units in the pool.
     * @type {string}
     * @memberof PoolDetail
     */
    synthUnits: string;
    /**
     * Int64, Total Units (synthUnits + liquidityUnits) in the pool.
     * @type {string}
     * @memberof PoolDetail
     */
    units: string;
    /**
     * Int64(e8), the total volume of swaps in the last 24h to and from Rune denoted in Rune.
     * @type {string}
     * @memberof PoolDetail
     */
    volume24h: string;
}
/**
 * 
 * @export
 * @interface PoolLegacyDetail
 */
export interface PoolLegacyDetail {
    /**
     * 
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    asset: string;
    /**
     * same as assetDepth from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    assetDepth: string;
    /**
     * same as addAssetLiquidityVolume from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    assetStakedTotal: string;
    /**
     * same as toAssetCount from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    buyAssetCount: string;
    /**
     * same as toAssetFees / toAssetCount from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    buyFeeAverage: string;
    /**
     * same as toAssetFees from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    buyFeesTotal: string;
    /**
     * same as toAssetAverageSlip from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    buySlipAverage: string;
    /**
     * same as toAssetVolume / toAssetCount from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    buyTxAverage: string;
    /**
     * same as toAssetVolume from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    buyVolume: string;
    /**
     * Float, Average Percentage Yield: annual return estimated using last weeks income, taking compound interest into account.
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    poolAPY: string;
    /**
     * same as 2*runeDepth from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    poolDepth: string;
    /**
     * same as totalFees / swapCount from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    poolFeeAverage: string;
    /**
     * same as totalFees from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    poolFeesTotal: string;
    /**
     * same as averageSlip from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    poolSlipAverage: string;
    /**
     * same as addLiquidityVolume from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    poolStakedTotal: string;
    /**
     * same as swapVolume / swapCount from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    poolTxAverage: string;
    /**
     * same as units from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    poolUnits: string;
    /**
     * Int64(e8), same as buyVolume + sellVolume
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    poolVolume: string;
    /**
     * same as assetPrice from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    price: string;
    /**
     * same as runeDepth from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    runeDepth: string;
    /**
     * same as addRuneLiquidityVolume from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    runeStakedTotal: string;
    /**
     * same as toRuneCount from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    sellAssetCount: string;
    /**
     * same as toRuneFees / toRuneCount from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    sellFeeAverage: string;
    /**
     * same as toRuneFees from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    sellFeesTotal: string;
    /**
     * same as toRuneAverageSlip from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    sellSlipAverage: string;
    /**
     * same as toRuneVolume / toRuneCount from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    sellTxAverage: string;
    /**
     * same as toRuneVolume from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    sellVolume: string;
    /**
     * same as addLiquidityCount from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    stakeTxCount: string;
    /**
     * same as uniqueMemberCount from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    stakersCount: string;
    /**
     * same as addLiquidityCount + withdrawCount from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    stakingTxCount: string;
    /**
     * same as status from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    status: string;
    /**
     * Int64, same as history/swaps:uniqueSwapperCount
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    swappersCount: string;
    /**
     * Int64, same as history/swaps:totalCount
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    swappingTxCount: string;
    /**
     * Int64(e8), same as swapVolume pool/stats?period=24h
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    volume24h: string;
    /**
     * same as withdrawCount from pool/stats
     * @type {string}
     * @memberof PoolLegacyDetail
     */
    withdrawTxCount: string;
}
/**
 * 
 * @export
 * @interface PoolStatsDetail
 */
export interface PoolStatsDetail {
    /**
     * Int64(e8), same as history/liquidity_changes:addAssetLiquidityVolume
     * @type {string}
     * @memberof PoolStatsDetail
     */
    addAssetLiquidityVolume: string;
    /**
     * Int64, same as history/liquidity_changes:addLiquidityCount
     * @type {string}
     * @memberof PoolStatsDetail
     */
    addLiquidityCount: string;
    /**
     * Int64(e8), same as history/liquidity_changes:addLiquidityVolume
     * @type {string}
     * @memberof PoolStatsDetail
     */
    addLiquidityVolume: string;
    /**
     * Int64(e8), same as history/liquidity_changes:addRuneLiquidityVolume
     * @type {string}
     * @memberof PoolStatsDetail
     */
    addRuneLiquidityVolume: string;
    /**
     * 
     * @type {string}
     * @memberof PoolStatsDetail
     */
    asset: string;
    /**
     * Int64(e8), the amount of Asset in the pool
     * @type {string}
     * @memberof PoolStatsDetail
     */
    assetDepth: string;
    /**
     * Float, price of asset in rune. I.e. rune amount / asset amount
     * @type {string}
     * @memberof PoolStatsDetail
     */
    assetPrice: string;
    /**
     * Float, the price of asset in USD (based on the deepest USD pool).
     * @type {string}
     * @memberof PoolStatsDetail
     */
    assetPriceUSD: string;
    /**
     * Float64 (Basis points, 0-10000, where 10000=100%), same as history/swaps:averageSlip
     * @type {string}
     * @memberof PoolStatsDetail
     */
    averageSlip: string;
    /**
     * Int64(e8), part of the withdrawRuneVolume which was payed because of impermanent loss protection. 
     * @type {string}
     * @memberof PoolStatsDetail
     */
    impermanentLossProtectionPaid: string;
    /**
     * Int64, Liquidity Units in the pool
     * @type {string}
     * @memberof PoolStatsDetail
     */
    liquidityUnits: string;
    /**
     * Float, Average Percentage Yield: annual return estimated using last weeks income, taking compound interest into account.
     * @type {string}
     * @memberof PoolStatsDetail
     */
    poolAPY: string;
    /**
     * Int64(e8), the amount of Rune in the pool
     * @type {string}
     * @memberof PoolStatsDetail
     */
    runeDepth: string;
    /**
     * The state of the pool, e.g. Available, Staged
     * @type {string}
     * @memberof PoolStatsDetail
     */
    status: string;
    /**
     * Int64, same as history/swaps:totalCount
     * @type {string}
     * @memberof PoolStatsDetail
     */
    swapCount: string;
    /**
     * Int64(e8), same as history/swaps:totalVolume
     * @type {string}
     * @memberof PoolStatsDetail
     */
    swapVolume: string;
    /**
     * Int64, Synth Units in the pool
     * @type {string}
     * @memberof PoolStatsDetail
     */
    synthUnits: string;
    /**
     * Float64 (Basis points, 0-10000, where 10000=100%), same as history/swaps:toAssetAverageSlip
     * @type {string}
     * @memberof PoolStatsDetail
     */
    toAssetAverageSlip: string;
    /**
     * Int64, same as history/swaps:toAssetCount
     * @type {string}
     * @memberof PoolStatsDetail
     */
    toAssetCount: string;
    /**
     * Int64(e8), same as history/swaps:toAssetFees
     * @type {string}
     * @memberof PoolStatsDetail
     */
    toAssetFees: string;
    /**
     * Int64(e8), same as history/swaps:toAssetVolume
     * @type {string}
     * @memberof PoolStatsDetail
     */
    toAssetVolume: string;
    /**
     * Float64 (Basis points, 0-10000, where 10000=100%), same as history/swaps:toRuneAverageSlip
     * @type {string}
     * @memberof PoolStatsDetail
     */
    toRuneAverageSlip: string;
    /**
     * Int64, same as history/swaps:toRuneCount
     * @type {string}
     * @memberof PoolStatsDetail
     */
    toRuneCount: string;
    /**
     * Int64(e8), same as history/swaps:toRuneFees
     * @type {string}
     * @memberof PoolStatsDetail
     */
    toRuneFees: string;
    /**
     * Int64(e8), same as history/swaps:toRuneVolume
     * @type {string}
     * @memberof PoolStatsDetail
     */
    toRuneVolume: string;
    /**
     * Int64(e8), same as history/swaps:totalFees
     * @type {string}
     * @memberof PoolStatsDetail
     */
    totalFees: string;
    /**
     * Int64, same as len(history/members?pool=POOL)
     * @type {string}
     * @memberof PoolStatsDetail
     */
    uniqueMemberCount: string;
    /**
     * Int64, number of unique adresses that initiated swaps transactions in the period. 
     * @type {string}
     * @memberof PoolStatsDetail
     */
    uniqueSwapperCount: string;
    /**
     * Int64, Total Units (synthUnits + liquidityUnits) in the pool
     * @type {string}
     * @memberof PoolStatsDetail
     */
    units: string;
    /**
     * Int64(e8), same as history/liquidity_changes:withdrawAssetVolume
     * @type {string}
     * @memberof PoolStatsDetail
     */
    withdrawAssetVolume: string;
    /**
     * Int64, same as history/liquidity_changes:withdrawCount
     * @type {string}
     * @memberof PoolStatsDetail
     */
    withdrawCount: string;
    /**
     * Int64(e8), same as history/liquidity_changes:withdrawRuneVolume
     * @type {string}
     * @memberof PoolStatsDetail
     */
    withdrawRuneVolume: string;
    /**
     * Int64(e8), same as history/liquidity_changes:withdrawVolume
     * @type {string}
     * @memberof PoolStatsDetail
     */
    withdrawVolume: string;
}
/**
 * 
 * @export
 * @interface PreflightStatus
 */
export interface PreflightStatus {
    /**
     * 
     * @type {number}
     * @memberof PreflightStatus
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof PreflightStatus
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof PreflightStatus
     */
    status: string;
}
/**
 * 
 * @export
 * @interface ProxiedNode
 */
export interface ProxiedNode {
    /**
     * 
     * @type {number}
     * @memberof ProxiedNode
     */
    active_block_height: number;
    /**
     * 
     * @type {string}
     * @memberof ProxiedNode
     */
    bond: string;
    /**
     * 
     * @type {string}
     * @memberof ProxiedNode
     */
    bond_address: string;
    /**
     * 
     * @type {string}
     * @memberof ProxiedNode
     */
    current_award: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProxiedNode
     */
    forced_to_leave: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProxiedNode
     */
    ip_address: string;
    /**
     * 
     * @type {ProxiedNodeJail}
     * @memberof ProxiedNode
     */
    jail: ProxiedNodeJail;
    /**
     * 
     * @type {number}
     * @memberof ProxiedNode
     */
    leave_height: number;
    /**
     * 
     * @type {string}
     * @memberof ProxiedNode
     */
    node_address: string;
    /**
     * 
     * @type {Array<ObservedChain>}
     * @memberof ProxiedNode
     */
    observe_chains: Array<ObservedChain>;
    /**
     * 
     * @type {PreflightStatus}
     * @memberof ProxiedNode
     */
    preflight_status: PreflightStatus;
    /**
     * 
     * @type {ProxiedNodePubKeySet}
     * @memberof ProxiedNode
     */
    pub_key_set: ProxiedNodePubKeySet;
    /**
     * 
     * @type {boolean}
     * @memberof ProxiedNode
     */
    requested_to_leave: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProxiedNode
     */
    signer_membership: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProxiedNode
     */
    slash_points: number;
    /**
     * 
     * @type {string}
     * @memberof ProxiedNode
     */
    status: string;
    /**
     * 
     * @type {number}
     * @memberof ProxiedNode
     */
    status_since: number;
    /**
     * 
     * @type {string}
     * @memberof ProxiedNode
     */
    validator_cons_pub_key: string;
    /**
     * 
     * @type {string}
     * @memberof ProxiedNode
     */
    version: string;
}
/**
 * 
 * @export
 * @interface ProxiedNodeJail
 */
export interface ProxiedNodeJail {
    /**
     * 
     * @type {string}
     * @memberof ProxiedNodeJail
     */
    node_address?: string;
}
/**
 * 
 * @export
 * @interface ProxiedNodePubKeySet
 */
export interface ProxiedNodePubKeySet {
    /**
     * 
     * @type {string}
     * @memberof ProxiedNodePubKeySet
     */
    ed25519: string;
    /**
     * 
     * @type {string}
     * @memberof ProxiedNodePubKeySet
     */
    secp256k1: string;
}
/**
 * 
 * @export
 * @interface Queue
 */
export interface Queue {
    /**
     * 
     * @type {number}
     * @memberof Queue
     */
    internal: number;
    /**
     * 
     * @type {number}
     * @memberof Queue
     */
    outbound: number;
    /**
     * 
     * @type {number}
     * @memberof Queue
     */
    swap: number;
}
/**
 * 
 * @export
 * @interface RefundMetadata
 */
export interface RefundMetadata {
    /**
     * List of network fees associated to an action. One network fee is charged for each outbound transaction
     * @type {Array<Coin>}
     * @memberof RefundMetadata
     */
    networkFees: Array<Coin>;
    /**
     * Reason for the refund
     * @type {string}
     * @memberof RefundMetadata
     */
    reason: string;
}
/**
 * 
 * @export
 * @interface StatsData
 */
export interface StatsData {
    /**
     * Int64, number of deposits since beginning.
     * @type {string}
     * @memberof StatsData
     */
    addLiquidityCount: string;
    /**
     * Int64(e8), total of deposits since beginning. 
     * @type {string}
     * @memberof StatsData
     */
    addLiquidityVolume: string;
    /**
     * Int64, unique users (addresses) initiating swaps in the last 24 hours.
     * @type {string}
     * @memberof StatsData
     */
    dailyActiveUsers: string;
    /**
     * Int64(e8), impermanent loss protection paid out. 
     * @type {string}
     * @memberof StatsData
     */
    impermanentLossProtectionPaid: string;
    /**
     * Int64, unique users (addresses) initiating swaps in the last 30 days.
     * @type {string}
     * @memberof StatsData
     */
    monthlyActiveUsers: string;
    /**
     * Int64(e8), current total Rune in the pools.
     * @type {string}
     * @memberof StatsData
     */
    runeDepth: string;
    /**
     * Float, the price of Rune based on the deepest USD pool.
     * @type {string}
     * @memberof StatsData
     */
    runePriceUSD: string;
    /**
     * Int64, number of swaps since beginning.
     * @type {string}
     * @memberof StatsData
     */
    swapCount: string;
    /**
     * Int64(e8), number of swaps in the last 24h.
     * @type {string}
     * @memberof StatsData
     */
    swapCount24h: string;
    /**
     * Int64, number of swaps in the last 30d.
     * @type {string}
     * @memberof StatsData
     */
    swapCount30d: string;
    /**
     * Int64(e8), total volume of swaps denoted in Rune since beginning.
     * @type {string}
     * @memberof StatsData
     */
    swapVolume: string;
    /**
     * Int64(e8), amount of native rune switched from erc20 or BEPSwap rune.
     * @type {string}
     * @memberof StatsData
     */
    switchedRune: string;
    /**
     * Int64, number of swaps from Rune to Asset since beginning.
     * @type {string}
     * @memberof StatsData
     */
    toAssetCount: string;
    /**
     * Int64, number of swaps from Asset to Rune since beginning.
     * @type {string}
     * @memberof StatsData
     */
    toRuneCount: string;
    /**
     * Int64, unique users (addresses) initiating swaps since beginning.
     * @type {string}
     * @memberof StatsData
     */
    uniqueSwapperCount: string;
    /**
     * Int64, number of withdraws since beginning.
     * @type {string}
     * @memberof StatsData
     */
    withdrawCount: string;
    /**
     * Int64(e8), total of withdraws since beginning. 
     * @type {string}
     * @memberof StatsData
     */
    withdrawVolume: string;
}
/**
 * 
 * @export
 * @interface StringConstants
 */
export interface StringConstants {
    /**
     * 
     * @type {string}
     * @memberof StringConstants
     */
    DefaultPoolStatus: string;
}
/**
 * 
 * @export
 * @interface SwapHistory
 */
export interface SwapHistory {
    /**
     * 
     * @type {Array<SwapHistoryItem>}
     * @memberof SwapHistory
     */
    intervals: Array<SwapHistoryItem>;
    /**
     * 
     * @type {SwapHistoryItem}
     * @memberof SwapHistory
     */
    meta: SwapHistoryItem;
}
/**
 * 
 * @export
 * @interface SwapHistoryItem
 */
export interface SwapHistoryItem {
    /**
     * Float64 (Basis points, 0-10000, where 10000=100%), the average slip by swap. Big swaps have the same weight as small swaps 
     * @type {string}
     * @memberof SwapHistoryItem
     */
    averageSlip: string;
    /**
     * Int64, The end time of bucket in unix timestamp
     * @type {string}
     * @memberof SwapHistoryItem
     */
    endTime: string;
    /**
     * Float, the price of Rune based on the deepest USD pool at the end of the interval. 
     * @type {string}
     * @memberof SwapHistoryItem
     */
    runePriceUSD: string;
    /**
     * Int64, The beginning time of bucket in unix timestamp
     * @type {string}
     * @memberof SwapHistoryItem
     */
    startTime: string;
    /**
     * Float64 (Basis points, 0-10000, where 10000=100%), the average slip for swaps to asset. Big swaps have the same weight as small swaps 
     * @type {string}
     * @memberof SwapHistoryItem
     */
    toAssetAverageSlip: string;
    /**
     * Int64, count of swaps from rune to asset
     * @type {string}
     * @memberof SwapHistoryItem
     */
    toAssetCount: string;
    /**
     * Int64(e8), the fees collected from swaps to asset denoted in rune
     * @type {string}
     * @memberof SwapHistoryItem
     */
    toAssetFees: string;
    /**
     * Int64(e8), volume of swaps from rune to asset denoted in rune
     * @type {string}
     * @memberof SwapHistoryItem
     */
    toAssetVolume: string;
    /**
     * Float64 (Basis points, 0-10000, where 10000=100%), the average slip for swaps to rune. Big swaps have the same weight as small swaps 
     * @type {string}
     * @memberof SwapHistoryItem
     */
    toRuneAverageSlip: string;
    /**
     * Int64, count of swaps from asset to rune
     * @type {string}
     * @memberof SwapHistoryItem
     */
    toRuneCount: string;
    /**
     * Int64(e8), the fees collected from swaps to rune
     * @type {string}
     * @memberof SwapHistoryItem
     */
    toRuneFees: string;
    /**
     * Int64(e8), volume of swaps from asset to rune denoted in rune
     * @type {string}
     * @memberof SwapHistoryItem
     */
    toRuneVolume: string;
    /**
     * Int64, toAssetCount + toRuneCount
     * @type {string}
     * @memberof SwapHistoryItem
     */
    totalCount: string;
    /**
     * Int64(e8), the sum of all fees collected denoted in rune
     * @type {string}
     * @memberof SwapHistoryItem
     */
    totalFees: string;
    /**
     * Int64(e8), toAssetVolume + toRuneVolume (denoted in rune)
     * @type {string}
     * @memberof SwapHistoryItem
     */
    totalVolume: string;
}
/**
 * 
 * @export
 * @interface SwapMetadata
 */
export interface SwapMetadata {
    /**
     * Int64(e8), RUNE amount charged as swap liquidity fee
     * @type {string}
     * @memberof SwapMetadata
     */
    liquidityFee: string;
    /**
     * List of network fees associated to an action. One network fee is charged for each outbound transaction
     * @type {Array<Coin>}
     * @memberof SwapMetadata
     */
    networkFees: Array<Coin>;
    /**
     * Int64 (Basis points, 0-10000, where 10000=100%), swap slip percentage
     * @type {string}
     * @memberof SwapMetadata
     */
    swapSlip: string;
    /**
     * Int64(e8), minimum output amount specified for the swap
     * @type {string}
     * @memberof SwapMetadata
     */
    swapTarget: string;
}
/**
 * 
 * @export
 * @interface THORNameDetails
 */
export interface THORNameDetails {
    /**
     * List details of all chains and their addresses for a given THORName
     * @type {Array<THORNameEntry>}
     * @memberof THORNameDetails
     */
    entries: Array<THORNameEntry>;
    /**
     * Int64, THORChain block height in which THORName expires
     * @type {string}
     * @memberof THORNameDetails
     */
    expire: string;
    /**
     * owner\'s THOR address
     * @type {string}
     * @memberof THORNameDetails
     */
    owner: string;
}
/**
 * 
 * @export
 * @interface THORNameEntry
 */
export interface THORNameEntry {
    /**
     * address on blockchain
     * @type {string}
     * @memberof THORNameEntry
     */
    address: string;
    /**
     * blockchain
     * @type {string}
     * @memberof THORNameEntry
     */
    chain: string;
}
/**
 * 
 * @export
 * @interface TVLHistory
 */
export interface TVLHistory {
    /**
     * 
     * @type {Array<TVLHistoryItem>}
     * @memberof TVLHistory
     */
    intervals: Array<TVLHistoryItem>;
    /**
     * 
     * @type {TVLHistoryItem}
     * @memberof TVLHistory
     */
    meta: TVLHistoryItem;
}
/**
 * 
 * @export
 * @interface TVLHistoryItem
 */
export interface TVLHistoryItem {
    /**
     * Int64, The end time of bucket in unix timestamp
     * @type {string}
     * @memberof TVLHistoryItem
     */
    endTime: string;
    /**
     * Float, the price of Rune based on the deepest USD pool at the end of the interval. 
     * @type {string}
     * @memberof TVLHistoryItem
     */
    runePriceUSD: string;
    /**
     * Int64, The beginning time of bucket in unix timestamp
     * @type {string}
     * @memberof TVLHistoryItem
     */
    startTime: string;
    /**
     * Int64(e8), the total amount of bonds (both active and standby) at the end of the interval
     * @type {string}
     * @memberof TVLHistoryItem
     */
    totalValueBonded?: string;
    /**
     * Int64(e8), total value locked in the chain (in rune) This equals `totalPooledValue + totalBondedValue`, as it combines the liquidity pools and bonds of the nodes. 
     * @type {string}
     * @memberof TVLHistoryItem
     */
    totalValueLocked?: string;
    /**
     * Int64(e8) in rune, the total pooled value (both assets and rune) in all of the pools at the end of the interval Note: this is twice the aggregate Rune depth of all pools. 
     * @type {string}
     * @memberof TVLHistoryItem
     */
    totalValuePooled: string;
}
/**
 * Transaction data
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * Sender address
     * @type {string}
     * @memberof Transaction
     */
    address: string;
    /**
     * 
     * @type {Array<Coin>}
     * @memberof Transaction
     */
    coins: Array<Coin>;
    /**
     * Transaction id hash. Some transactions (such as outbound transactions made in the native asset) may have a zero value.
     * @type {string}
     * @memberof Transaction
     */
    txID: string;
}
/**
 * 
 * @export
 * @interface WithdrawMetadata
 */
export interface WithdrawMetadata {
    /**
     * Decimal (-1.0 <=> 1.0), indicates how assymetrical the withdrawal was. 0 means totally symetrical
     * @type {string}
     * @memberof WithdrawMetadata
     */
    asymmetry: string;
    /**
     * Int64 (Basis points, 0-10000, where 10000=100%), percentage of total pool ownership withdrawn
     * @type {string}
     * @memberof WithdrawMetadata
     */
    basisPoints: string;
    /**
     * Int64, amount of liquidity units removed from the member as result of the withdrawal
     * @type {string}
     * @memberof WithdrawMetadata
     */
    liquidityUnits: string;
    /**
     * List of network fees associated to an action. One network fee is charged for each outbound transaction
     * @type {Array<Coin>}
     * @memberof WithdrawMetadata
     */
    networkFees: Array<Coin>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List actions along with their related transactions. An action is generated by one or more inbound transactions with the intended action set in the transaction memo. The action may result in one or more outbound transactions. Results are paginated by sets of 50. Filters may be applied to query actions. 
         * @summary Actions List
         * @param {number} limit pagination limit
         * @param {number} offset pagination offset
         * @param {string} [address] Comma separated list. Address of sender or recipient of any in/out transaction related to the action. 
         * @param {string} [txid] ID of any in/out tx related to the action
         * @param {string} [asset] Any asset that is part of the action (CHAIN.SYMBOL)
         * @param {string} [type] One or more comma separated unique types of action (swap, addLiquidity, withdraw, donate, refund, switch) 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActions: async (limit: number, offset: number, address?: string, txid?: string, asset?: string, type?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            if (limit === null || limit === undefined) {
                throw new RequiredError('limit','Required parameter limit was null or undefined when calling getActions.');
            }
            // verify required parameter 'offset' is not null or undefined
            if (offset === null || offset === undefined) {
                throw new RequiredError('offset','Required parameter offset was null or undefined when calling getActions.');
            }
            const localVarPath = `/v2/actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }

            if (txid !== undefined) {
                localVarQueryParameter['txid'] = txid;
            }

            if (asset !== undefined) {
                localVarQueryParameter['asset'] = asset;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the asset and rune depths and price. The values report the state at the end of each interval.  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.   * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
         * @summary Depth and Price History
         * @param {string} pool Return stats for this single pool.
         * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
         * @param {number} [count] Number of intervals to return. Should be between [1..400].
         * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now.
         * @param {number} [from] Start time of the query as unix timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepthHistory: async (pool: string, interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pool' is not null or undefined
            if (pool === null || pool === undefined) {
                throw new RequiredError('pool','Required parameter pool was null or undefined when calling getDepthHistory.');
            }
            const localVarPath = `/v2/history/depths/{pool}`
                .replace(`{${"pool"}}`, encodeURIComponent(String(pool)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns earnings data for the specified interval.  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.   * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
         * @summary Earnings History
         * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
         * @param {number} [count] Number of intervals to return. Should be between [1..400].
         * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now.
         * @param {number} [from] Start time of the query as unix timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarningsHistory: async (interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/history/earnings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an object containing the health response of the API
         * @summary Health Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns withdrawals and deposits for given time interval. If pool is not specified returns for all pools  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.   * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
         * @summary Liquidity Changes History
         * @param {string} [pool] Return stats for given pool. Returns sum of all pools if missing
         * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
         * @param {number} [count] Number of intervals to return. Should be between [1..400]
         * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now
         * @param {number} [from] Start time of the query as unix timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiquidityHistory: async (pool?: string, interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/history/liquidity_changes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pool !== undefined) {
                localVarQueryParameter['pool'] = pool;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an array of statistics for all the liquidity providers associated with a given member address.
         * @summary Member Details
         * @param {string} address Address to match liquidity providers. Either a rune or an asset address may be given.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberDetail: async (address: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'address' is not null or undefined
            if (address === null || address === undefined) {
                throw new RequiredError('address','Required parameter address was null or undefined when calling getMemberDetail.');
            }
            const localVarPath = `/v2/member/{address}`
                .replace(`{${"address"}}`, encodeURIComponent(String(address)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an array containing the addresses for all pool members. Addresses are only shown once. If there\'s both a RUNE address and an asset address for a member, only the RUNE address will be shown. 
         * @summary Members List
         * @param {string} [pool] Return only members present in the pool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersAdresses: async (pool?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pool !== undefined) {
                localVarQueryParameter['pool'] = pool;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an object containing Network data
         * @summary Network Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworkData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/network`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Node public keys and adresses.
         * @summary Nodes List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/nodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns details of the pool: depths, price, 24h volume, APY. 
         * @summary Details of a Pool
         * @param {string} asset pool name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPool: async (asset: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'asset' is not null or undefined
            if (asset === null || asset === undefined) {
                throw new RequiredError('asset','Required parameter asset was null or undefined when calling getPool.');
            }
            const localVarPath = `/v2/pool/{asset}`
                .replace(`{${"asset"}}`, encodeURIComponent(String(asset)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Statistics about the pool. The description of the fields have pointers about the corresponding v2/history location. Visit the history endpoint for drilldowns. 
         * @summary Pool Statistics
         * @param {string} asset pool name
         * @param {'1h' | '24h' | '7d' | '30d' | '90d' | '365d' | 'all'} [period] Restricts aggregation type fields to the last period only. Default is 30d. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoolStats: async (asset: string, period?: '1h' | '24h' | '7d' | '30d' | '90d' | '365d' | 'all', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'asset' is not null or undefined
            if (asset === null || asset === undefined) {
                throw new RequiredError('asset','Required parameter asset was null or undefined when calling getPoolStats.');
            }
            const localVarPath = `/v2/pool/{asset}/stats`
                .replace(`{${"asset"}}`, encodeURIComponent(String(asset)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Legacy, V1 style names for backward compatibility. Please migrate to GetPoolStats, check the fields documentation for details. 
         * @summary Pool Statistics (v1 naming)
         * @param {string} asset pool name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoolStatsLegacy: async (asset: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'asset' is not null or undefined
            if (asset === null || asset === undefined) {
                throw new RequiredError('asset','Required parameter asset was null or undefined when calling getPoolStatsLegacy.');
            }
            const localVarPath = `/v2/pool/{asset}/stats/legacy`
                .replace(`{${"asset"}}`, encodeURIComponent(String(asset)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an array containing details for a set of pools
         * @summary Pools List
         * @param {'available' | 'staged' | 'suspended'} [status] Filter for only pools with this status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPools: async (status?: 'available' | 'staged' | 'suspended', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/pools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Constant values used by THORChain , some of the values can be overrided by mimir
         * @summary Proxied THORChain Constants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxiedConstants: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/thorchain/constants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Inbound addresses will return a list of address , one per chain. The address might change frequently if THORChain has multiple asgards.
         * @summary Proxied THORChain Inbound Addresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxiedInboundAddresses: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/thorchain/inbound_addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve lastest block infomation across all chains.
         * @summary Proxied THORChain Lastblock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxiedLastblock: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/thorchain/lastblock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the proxied nodes endpoint from thornode
         * @summary Proxied THORChain Nodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxiedNodes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/thorchain/nodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the proxied queue endpoint from thornode
         * @summary Proxied THORChain Queue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxiedQueue: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/thorchain/queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an object containing global stats for all pools and all transactions
         * @summary Global Stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns swap count, volume, fees, slip in specified interval. If pool is not specified returns for all pools  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.   * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
         * @summary Swaps History
         * @param {string} [pool] Return history given pool. Returns sum of all pools if missing.
         * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
         * @param {number} [count] Number of intervals to return. Should be between [1..400].
         * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now.
         * @param {number} [from] Start time of the query as unix timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwapHistory: async (pool?: string, interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/history/swaps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pool !== undefined) {
                localVarQueryParameter['pool'] = pool;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an array of chains and their addresses associated with the given THORName
         * @summary THORName Details
         * @param {string} name a THORName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTHORNameDetail: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getTHORNameDetail.');
            }
            const localVarPath = `/v2/thorname/lookup/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an array of THORNames associated with the given address
         * @summary Gives a list of THORNames by reverse lookup
         * @param {string} address Address to match THORNames against.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTHORNamesByAddress: async (address: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'address' is not null or undefined
            if (address === null || address === undefined) {
                throw new RequiredError('address','Required parameter address was null or undefined when calling getTHORNamesByAddress.');
            }
            const localVarPath = `/v2/thorname/rlookup/{address}`
                .replace(`{${"address"}}`, encodeURIComponent(String(address)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns total pool depths, total bonds, and total value locked in specified interval.  Total Value Locked = Total Bonds + 2 * Total Pool Depths  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.  * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
         * @summary Total Value Locked History
         * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
         * @param {number} [count] Number of intervals to return. Should be between [1..400].
         * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now.
         * @param {number} [from] Start time of the query as unix timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTVLHistory: async (interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/history/tvl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * List actions along with their related transactions. An action is generated by one or more inbound transactions with the intended action set in the transaction memo. The action may result in one or more outbound transactions. Results are paginated by sets of 50. Filters may be applied to query actions. 
         * @summary Actions List
         * @param {number} limit pagination limit
         * @param {number} offset pagination offset
         * @param {string} [address] Comma separated list. Address of sender or recipient of any in/out transaction related to the action. 
         * @param {string} [txid] ID of any in/out tx related to the action
         * @param {string} [asset] Any asset that is part of the action (CHAIN.SYMBOL)
         * @param {string} [type] One or more comma separated unique types of action (swap, addLiquidity, withdraw, donate, refund, switch) 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActions(limit: number, offset: number, address?: string, txid?: string, asset?: string, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getActions(limit, offset, address, txid, asset, type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns the asset and rune depths and price. The values report the state at the end of each interval.  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.   * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
         * @summary Depth and Price History
         * @param {string} pool Return stats for this single pool.
         * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
         * @param {number} [count] Number of intervals to return. Should be between [1..400].
         * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now.
         * @param {number} [from] Start time of the query as unix timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepthHistory(pool: string, interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepthHistory>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDepthHistory(pool, interval, count, to, from, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns earnings data for the specified interval.  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.   * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
         * @summary Earnings History
         * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
         * @param {number} [count] Number of intervals to return. Should be between [1..400].
         * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now.
         * @param {number} [from] Start time of the query as unix timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEarningsHistory(interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EarningsHistory>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getEarningsHistory(interval, count, to, from, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns an object containing the health response of the API
         * @summary Health Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Health>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getHealth(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns withdrawals and deposits for given time interval. If pool is not specified returns for all pools  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.   * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
         * @summary Liquidity Changes History
         * @param {string} [pool] Return stats for given pool. Returns sum of all pools if missing
         * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
         * @param {number} [count] Number of intervals to return. Should be between [1..400]
         * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now
         * @param {number} [from] Start time of the query as unix timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLiquidityHistory(pool?: string, interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LiquidityHistory>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getLiquidityHistory(pool, interval, count, to, from, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns an array of statistics for all the liquidity providers associated with a given member address.
         * @summary Member Details
         * @param {string} address Address to match liquidity providers. Either a rune or an asset address may be given.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemberDetail(address: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberDetails>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getMemberDetail(address, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns an array containing the addresses for all pool members. Addresses are only shown once. If there\'s both a RUNE address and an asset address for a member, only the RUNE address will be shown. 
         * @summary Members List
         * @param {string} [pool] Return only members present in the pool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMembersAdresses(pool?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getMembersAdresses(pool, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns an object containing Network data
         * @summary Network Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNetworkData(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Network>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getNetworkData(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns a list of Node public keys and adresses.
         * @summary Nodes List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Node>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getNodes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns details of the pool: depths, price, 24h volume, APY. 
         * @summary Details of a Pool
         * @param {string} asset pool name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPool(asset: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoolDetail>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getPool(asset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Statistics about the pool. The description of the fields have pointers about the corresponding v2/history location. Visit the history endpoint for drilldowns. 
         * @summary Pool Statistics
         * @param {string} asset pool name
         * @param {'1h' | '24h' | '7d' | '30d' | '90d' | '365d' | 'all'} [period] Restricts aggregation type fields to the last period only. Default is 30d. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoolStats(asset: string, period?: '1h' | '24h' | '7d' | '30d' | '90d' | '365d' | 'all', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoolStatsDetail>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getPoolStats(asset, period, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Legacy, V1 style names for backward compatibility. Please migrate to GetPoolStats, check the fields documentation for details. 
         * @summary Pool Statistics (v1 naming)
         * @param {string} asset pool name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoolStatsLegacy(asset: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoolLegacyDetail>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getPoolStatsLegacy(asset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns an array containing details for a set of pools
         * @summary Pools List
         * @param {'available' | 'staged' | 'suspended'} [status] Filter for only pools with this status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPools(status?: 'available' | 'staged' | 'suspended', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PoolDetail>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getPools(status, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Constant values used by THORChain , some of the values can be overrided by mimir
         * @summary Proxied THORChain Constants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProxiedConstants(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Constants>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProxiedConstants(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Inbound addresses will return a list of address , one per chain. The address might change frequently if THORChain has multiple asgards.
         * @summary Proxied THORChain Inbound Addresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProxiedInboundAddresses(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InboundAddressesItem>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProxiedInboundAddresses(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieve lastest block infomation across all chains.
         * @summary Proxied THORChain Lastblock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProxiedLastblock(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LastblockItem>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProxiedLastblock(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns the proxied nodes endpoint from thornode
         * @summary Proxied THORChain Nodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProxiedNodes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProxiedNode>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProxiedNodes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns the proxied queue endpoint from thornode
         * @summary Proxied THORChain Queue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProxiedQueue(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Queue>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProxiedQueue(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns an object containing global stats for all pools and all transactions
         * @summary Global Stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStats(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatsData>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getStats(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns swap count, volume, fees, slip in specified interval. If pool is not specified returns for all pools  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.   * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
         * @summary Swaps History
         * @param {string} [pool] Return history given pool. Returns sum of all pools if missing.
         * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
         * @param {number} [count] Number of intervals to return. Should be between [1..400].
         * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now.
         * @param {number} [from] Start time of the query as unix timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSwapHistory(pool?: string, interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SwapHistory>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getSwapHistory(pool, interval, count, to, from, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns an array of chains and their addresses associated with the given THORName
         * @summary THORName Details
         * @param {string} name a THORName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTHORNameDetail(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<THORNameDetails>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTHORNameDetail(name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns an array of THORNames associated with the given address
         * @summary Gives a list of THORNames by reverse lookup
         * @param {string} address Address to match THORNames against.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTHORNamesByAddress(address: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTHORNamesByAddress(address, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns total pool depths, total bonds, and total value locked in specified interval.  Total Value Locked = Total Bonds + 2 * Total Pool Depths  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.  * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
         * @summary Total Value Locked History
         * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
         * @param {number} [count] Number of intervals to return. Should be between [1..400].
         * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now.
         * @param {number} [from] Start time of the query as unix timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTVLHistory(interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TVLHistory>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTVLHistory(interval, count, to, from, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * List actions along with their related transactions. An action is generated by one or more inbound transactions with the intended action set in the transaction memo. The action may result in one or more outbound transactions. Results are paginated by sets of 50. Filters may be applied to query actions. 
         * @summary Actions List
         * @param {number} limit pagination limit
         * @param {number} offset pagination offset
         * @param {string} [address] Comma separated list. Address of sender or recipient of any in/out transaction related to the action. 
         * @param {string} [txid] ID of any in/out tx related to the action
         * @param {string} [asset] Any asset that is part of the action (CHAIN.SYMBOL)
         * @param {string} [type] One or more comma separated unique types of action (swap, addLiquidity, withdraw, donate, refund, switch) 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActions(limit: number, offset: number, address?: string, txid?: string, asset?: string, type?: string, options?: any): AxiosPromise<InlineResponse200> {
            return DefaultApiFp(configuration).getActions(limit, offset, address, txid, asset, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the asset and rune depths and price. The values report the state at the end of each interval.  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.   * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
         * @summary Depth and Price History
         * @param {string} pool Return stats for this single pool.
         * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
         * @param {number} [count] Number of intervals to return. Should be between [1..400].
         * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now.
         * @param {number} [from] Start time of the query as unix timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepthHistory(pool: string, interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options?: any): AxiosPromise<DepthHistory> {
            return DefaultApiFp(configuration).getDepthHistory(pool, interval, count, to, from, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns earnings data for the specified interval.  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.   * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
         * @summary Earnings History
         * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
         * @param {number} [count] Number of intervals to return. Should be between [1..400].
         * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now.
         * @param {number} [from] Start time of the query as unix timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarningsHistory(interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options?: any): AxiosPromise<EarningsHistory> {
            return DefaultApiFp(configuration).getEarningsHistory(interval, count, to, from, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an object containing the health response of the API
         * @summary Health Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: any): AxiosPromise<Health> {
            return DefaultApiFp(configuration).getHealth(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns withdrawals and deposits for given time interval. If pool is not specified returns for all pools  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.   * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
         * @summary Liquidity Changes History
         * @param {string} [pool] Return stats for given pool. Returns sum of all pools if missing
         * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
         * @param {number} [count] Number of intervals to return. Should be between [1..400]
         * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now
         * @param {number} [from] Start time of the query as unix timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiquidityHistory(pool?: string, interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options?: any): AxiosPromise<LiquidityHistory> {
            return DefaultApiFp(configuration).getLiquidityHistory(pool, interval, count, to, from, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an array of statistics for all the liquidity providers associated with a given member address.
         * @summary Member Details
         * @param {string} address Address to match liquidity providers. Either a rune or an asset address may be given.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemberDetail(address: string, options?: any): AxiosPromise<MemberDetails> {
            return DefaultApiFp(configuration).getMemberDetail(address, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an array containing the addresses for all pool members. Addresses are only shown once. If there\'s both a RUNE address and an asset address for a member, only the RUNE address will be shown. 
         * @summary Members List
         * @param {string} [pool] Return only members present in the pool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersAdresses(pool?: string, options?: any): AxiosPromise<Array<string>> {
            return DefaultApiFp(configuration).getMembersAdresses(pool, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an object containing Network data
         * @summary Network Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworkData(options?: any): AxiosPromise<Network> {
            return DefaultApiFp(configuration).getNetworkData(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Node public keys and adresses.
         * @summary Nodes List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodes(options?: any): AxiosPromise<Array<Node>> {
            return DefaultApiFp(configuration).getNodes(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns details of the pool: depths, price, 24h volume, APY. 
         * @summary Details of a Pool
         * @param {string} asset pool name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPool(asset: string, options?: any): AxiosPromise<PoolDetail> {
            return DefaultApiFp(configuration).getPool(asset, options).then((request) => request(axios, basePath));
        },
        /**
         * Statistics about the pool. The description of the fields have pointers about the corresponding v2/history location. Visit the history endpoint for drilldowns. 
         * @summary Pool Statistics
         * @param {string} asset pool name
         * @param {'1h' | '24h' | '7d' | '30d' | '90d' | '365d' | 'all'} [period] Restricts aggregation type fields to the last period only. Default is 30d. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoolStats(asset: string, period?: '1h' | '24h' | '7d' | '30d' | '90d' | '365d' | 'all', options?: any): AxiosPromise<PoolStatsDetail> {
            return DefaultApiFp(configuration).getPoolStats(asset, period, options).then((request) => request(axios, basePath));
        },
        /**
         * Legacy, V1 style names for backward compatibility. Please migrate to GetPoolStats, check the fields documentation for details. 
         * @summary Pool Statistics (v1 naming)
         * @param {string} asset pool name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoolStatsLegacy(asset: string, options?: any): AxiosPromise<PoolLegacyDetail> {
            return DefaultApiFp(configuration).getPoolStatsLegacy(asset, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an array containing details for a set of pools
         * @summary Pools List
         * @param {'available' | 'staged' | 'suspended'} [status] Filter for only pools with this status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPools(status?: 'available' | 'staged' | 'suspended', options?: any): AxiosPromise<Array<PoolDetail>> {
            return DefaultApiFp(configuration).getPools(status, options).then((request) => request(axios, basePath));
        },
        /**
         * Constant values used by THORChain , some of the values can be overrided by mimir
         * @summary Proxied THORChain Constants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxiedConstants(options?: any): AxiosPromise<Constants> {
            return DefaultApiFp(configuration).getProxiedConstants(options).then((request) => request(axios, basePath));
        },
        /**
         * Inbound addresses will return a list of address , one per chain. The address might change frequently if THORChain has multiple asgards.
         * @summary Proxied THORChain Inbound Addresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxiedInboundAddresses(options?: any): AxiosPromise<Array<InboundAddressesItem>> {
            return DefaultApiFp(configuration).getProxiedInboundAddresses(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve lastest block infomation across all chains.
         * @summary Proxied THORChain Lastblock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxiedLastblock(options?: any): AxiosPromise<Array<LastblockItem>> {
            return DefaultApiFp(configuration).getProxiedLastblock(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the proxied nodes endpoint from thornode
         * @summary Proxied THORChain Nodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxiedNodes(options?: any): AxiosPromise<Array<ProxiedNode>> {
            return DefaultApiFp(configuration).getProxiedNodes(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the proxied queue endpoint from thornode
         * @summary Proxied THORChain Queue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProxiedQueue(options?: any): AxiosPromise<Queue> {
            return DefaultApiFp(configuration).getProxiedQueue(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an object containing global stats for all pools and all transactions
         * @summary Global Stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats(options?: any): AxiosPromise<StatsData> {
            return DefaultApiFp(configuration).getStats(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns swap count, volume, fees, slip in specified interval. If pool is not specified returns for all pools  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.   * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
         * @summary Swaps History
         * @param {string} [pool] Return history given pool. Returns sum of all pools if missing.
         * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
         * @param {number} [count] Number of intervals to return. Should be between [1..400].
         * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now.
         * @param {number} [from] Start time of the query as unix timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwapHistory(pool?: string, interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options?: any): AxiosPromise<SwapHistory> {
            return DefaultApiFp(configuration).getSwapHistory(pool, interval, count, to, from, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an array of chains and their addresses associated with the given THORName
         * @summary THORName Details
         * @param {string} name a THORName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTHORNameDetail(name: string, options?: any): AxiosPromise<THORNameDetails> {
            return DefaultApiFp(configuration).getTHORNameDetail(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an array of THORNames associated with the given address
         * @summary Gives a list of THORNames by reverse lookup
         * @param {string} address Address to match THORNames against.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTHORNamesByAddress(address: string, options?: any): AxiosPromise<Array<string>> {
            return DefaultApiFp(configuration).getTHORNamesByAddress(address, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns total pool depths, total bonds, and total value locked in specified interval.  Total Value Locked = Total Bonds + 2 * Total Pool Depths  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.  * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
         * @summary Total Value Locked History
         * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
         * @param {number} [count] Number of intervals to return. Should be between [1..400].
         * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now.
         * @param {number} [from] Start time of the query as unix timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTVLHistory(interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options?: any): AxiosPromise<TVLHistory> {
            return DefaultApiFp(configuration).getTVLHistory(interval, count, to, from, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * List actions along with their related transactions. An action is generated by one or more inbound transactions with the intended action set in the transaction memo. The action may result in one or more outbound transactions. Results are paginated by sets of 50. Filters may be applied to query actions. 
     * @summary Actions List
     * @param {number} limit pagination limit
     * @param {number} offset pagination offset
     * @param {string} [address] Comma separated list. Address of sender or recipient of any in/out transaction related to the action. 
     * @param {string} [txid] ID of any in/out tx related to the action
     * @param {string} [asset] Any asset that is part of the action (CHAIN.SYMBOL)
     * @param {string} [type] One or more comma separated unique types of action (swap, addLiquidity, withdraw, donate, refund, switch) 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getActions(limit: number, offset: number, address?: string, txid?: string, asset?: string, type?: string, options?: any) {
        return DefaultApiFp(this.configuration).getActions(limit, offset, address, txid, asset, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the asset and rune depths and price. The values report the state at the end of each interval.  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.   * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
     * @summary Depth and Price History
     * @param {string} pool Return stats for this single pool.
     * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
     * @param {number} [count] Number of intervals to return. Should be between [1..400].
     * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now.
     * @param {number} [from] Start time of the query as unix timestamp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDepthHistory(pool: string, interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options?: any) {
        return DefaultApiFp(this.configuration).getDepthHistory(pool, interval, count, to, from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns earnings data for the specified interval.  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.   * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
     * @summary Earnings History
     * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
     * @param {number} [count] Number of intervals to return. Should be between [1..400].
     * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now.
     * @param {number} [from] Start time of the query as unix timestamp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEarningsHistory(interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options?: any) {
        return DefaultApiFp(this.configuration).getEarningsHistory(interval, count, to, from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an object containing the health response of the API
     * @summary Health Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHealth(options?: any) {
        return DefaultApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns withdrawals and deposits for given time interval. If pool is not specified returns for all pools  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.   * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
     * @summary Liquidity Changes History
     * @param {string} [pool] Return stats for given pool. Returns sum of all pools if missing
     * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
     * @param {number} [count] Number of intervals to return. Should be between [1..400]
     * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now
     * @param {number} [from] Start time of the query as unix timestamp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLiquidityHistory(pool?: string, interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options?: any) {
        return DefaultApiFp(this.configuration).getLiquidityHistory(pool, interval, count, to, from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an array of statistics for all the liquidity providers associated with a given member address.
     * @summary Member Details
     * @param {string} address Address to match liquidity providers. Either a rune or an asset address may be given.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMemberDetail(address: string, options?: any) {
        return DefaultApiFp(this.configuration).getMemberDetail(address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an array containing the addresses for all pool members. Addresses are only shown once. If there\'s both a RUNE address and an asset address for a member, only the RUNE address will be shown. 
     * @summary Members List
     * @param {string} [pool] Return only members present in the pool.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMembersAdresses(pool?: string, options?: any) {
        return DefaultApiFp(this.configuration).getMembersAdresses(pool, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an object containing Network data
     * @summary Network Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNetworkData(options?: any) {
        return DefaultApiFp(this.configuration).getNetworkData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Node public keys and adresses.
     * @summary Nodes List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNodes(options?: any) {
        return DefaultApiFp(this.configuration).getNodes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns details of the pool: depths, price, 24h volume, APY. 
     * @summary Details of a Pool
     * @param {string} asset pool name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPool(asset: string, options?: any) {
        return DefaultApiFp(this.configuration).getPool(asset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Statistics about the pool. The description of the fields have pointers about the corresponding v2/history location. Visit the history endpoint for drilldowns. 
     * @summary Pool Statistics
     * @param {string} asset pool name
     * @param {'1h' | '24h' | '7d' | '30d' | '90d' | '365d' | 'all'} [period] Restricts aggregation type fields to the last period only. Default is 30d. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPoolStats(asset: string, period?: '1h' | '24h' | '7d' | '30d' | '90d' | '365d' | 'all', options?: any) {
        return DefaultApiFp(this.configuration).getPoolStats(asset, period, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Legacy, V1 style names for backward compatibility. Please migrate to GetPoolStats, check the fields documentation for details. 
     * @summary Pool Statistics (v1 naming)
     * @param {string} asset pool name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPoolStatsLegacy(asset: string, options?: any) {
        return DefaultApiFp(this.configuration).getPoolStatsLegacy(asset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an array containing details for a set of pools
     * @summary Pools List
     * @param {'available' | 'staged' | 'suspended'} [status] Filter for only pools with this status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPools(status?: 'available' | 'staged' | 'suspended', options?: any) {
        return DefaultApiFp(this.configuration).getPools(status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Constant values used by THORChain , some of the values can be overrided by mimir
     * @summary Proxied THORChain Constants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProxiedConstants(options?: any) {
        return DefaultApiFp(this.configuration).getProxiedConstants(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Inbound addresses will return a list of address , one per chain. The address might change frequently if THORChain has multiple asgards.
     * @summary Proxied THORChain Inbound Addresses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProxiedInboundAddresses(options?: any) {
        return DefaultApiFp(this.configuration).getProxiedInboundAddresses(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve lastest block infomation across all chains.
     * @summary Proxied THORChain Lastblock
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProxiedLastblock(options?: any) {
        return DefaultApiFp(this.configuration).getProxiedLastblock(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the proxied nodes endpoint from thornode
     * @summary Proxied THORChain Nodes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProxiedNodes(options?: any) {
        return DefaultApiFp(this.configuration).getProxiedNodes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the proxied queue endpoint from thornode
     * @summary Proxied THORChain Queue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProxiedQueue(options?: any) {
        return DefaultApiFp(this.configuration).getProxiedQueue(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an object containing global stats for all pools and all transactions
     * @summary Global Stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStats(options?: any) {
        return DefaultApiFp(this.configuration).getStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns swap count, volume, fees, slip in specified interval. If pool is not specified returns for all pools  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.   * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
     * @summary Swaps History
     * @param {string} [pool] Return history given pool. Returns sum of all pools if missing.
     * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
     * @param {number} [count] Number of intervals to return. Should be between [1..400].
     * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now.
     * @param {number} [from] Start time of the query as unix timestamp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSwapHistory(pool?: string, interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options?: any) {
        return DefaultApiFp(this.configuration).getSwapHistory(pool, interval, count, to, from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an array of chains and their addresses associated with the given THORName
     * @summary THORName Details
     * @param {string} name a THORName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTHORNameDetail(name: string, options?: any) {
        return DefaultApiFp(this.configuration).getTHORNameDetail(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an array of THORNames associated with the given address
     * @summary Gives a list of THORNames by reverse lookup
     * @param {string} address Address to match THORNames against.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTHORNamesByAddress(address: string, options?: any) {
        return DefaultApiFp(this.configuration).getTHORNamesByAddress(address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns total pool depths, total bonds, and total value locked in specified interval.  Total Value Locked = Total Bonds + 2 * Total Pool Depths  History endpoint has two modes: * With Interval parameter it returns a series of time buckets. From and To dates will   be rounded to the Interval boundaries. * Without Interval parameter a single From..To search is performed with exact timestamps.  * Interval: possible values: 5min, hour, day, week, month, quarter, year. * count: [1..400]. Defines number of intervals. Don\'t provide if Interval is missing. * from/to: optional int, unix second.  Possible usages with interval. * last 10 days: `?interval=day&count=10` * last 10 days before to: `?interval=day&count=10&to=1608825600` * next 10 days after from: `?interval=day&count=10&from=1606780800` * Days between from and to. From defaults to start of chain, to defaults to now.   Only the first 400 intervals are returned:   `interval=day&from=1606780800&to=1608825600`  Pagination is possible with from&count and then using the returned meta.endTime as the From parameter of the next query.  Possible configurations without interval: * exact search for one time frame: `?from=1606780899&to=1608825600` * one time frame until now: `?from=1606780899` * from chain start until now: no query parameters 
     * @summary Total Value Locked History
     * @param {'5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'} [interval] Interval of calculations
     * @param {number} [count] Number of intervals to return. Should be between [1..400].
     * @param {number} [to] End time of the query as unix timestamp. If only count is given, defaults to now.
     * @param {number} [from] Start time of the query as unix timestamp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTVLHistory(interval?: '5min' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year', count?: number, to?: number, from?: number, options?: any) {
        return DefaultApiFp(this.configuration).getTVLHistory(interval, count, to, from, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpecificationApi - axios parameter creator
 * @export
 */
export const SpecificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Swagger/OpenAPI 3.0 specification generated documents.
         * @summary Documentation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocs: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/doc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns human and machine readable swagger/openapi specification
         * @summary Swagger File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwagger: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/swagger.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpecificationApi - functional programming interface
 * @export
 */
export const SpecificationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Swagger/OpenAPI 3.0 specification generated documents.
         * @summary Documentation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocs(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SpecificationApiAxiosParamCreator(configuration).getDocs(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns human and machine readable swagger/openapi specification
         * @summary Swagger File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSwagger(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SpecificationApiAxiosParamCreator(configuration).getSwagger(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SpecificationApi - factory interface
 * @export
 */
export const SpecificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Swagger/OpenAPI 3.0 specification generated documents.
         * @summary Documentation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocs(options?: any): AxiosPromise<void> {
            return SpecificationApiFp(configuration).getDocs(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns human and machine readable swagger/openapi specification
         * @summary Swagger File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwagger(options?: any): AxiosPromise<void> {
            return SpecificationApiFp(configuration).getSwagger(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpecificationApi - object-oriented interface
 * @export
 * @class SpecificationApi
 * @extends {BaseAPI}
 */
export class SpecificationApi extends BaseAPI {
    /**
     * Swagger/OpenAPI 3.0 specification generated documents.
     * @summary Documentation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecificationApi
     */
    public getDocs(options?: any) {
        return SpecificationApiFp(this.configuration).getDocs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns human and machine readable swagger/openapi specification
     * @summary Swagger File
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecificationApi
     */
    public getSwagger(options?: any) {
        return SpecificationApiFp(this.configuration).getSwagger(options).then((request) => request(this.axios, this.basePath));
    }
}


