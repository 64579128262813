import React, { useCallback, useMemo } from 'react'

import { Scrollbars } from 'react-custom-scrollbars'
import { useHistory } from 'react-router'

import { getRuneToUpgrade } from 'multichain-sdk'

import { useApp } from 'redux/app/hooks'
import { useWallet } from 'redux/wallet/hooks'

import {
  SWAP_ROUTE,
  UPGRADE_RUNE_ROUTE,
  ADD_LIQUIDITY_ROUTE,
  WITHDRAW_ROUTE,
  LIQUIDITY_ROUTE,
} from 'settings/constants'

import { Header } from '../Header'
import { Alert } from '../UIElements'
import WalletModal from '../WalletModal'
import * as Styled from './Layout.style'

export type Props = {
  transparent?: boolean
  children: React.ReactNode
}

export const Layout = (props: Props) => {
  const { children, transparent = false } = props

  const history = useHistory()
  const { wallet } = useWallet()
  const { showAnnouncement, setReadStatus } = useApp()

  const oldRune: string | null = useMemo(() => {
    if (wallet) {
      const runesToUpgrade = getRuneToUpgrade(wallet)

      if (runesToUpgrade && runesToUpgrade.length > 0) {
        const oldRuneChain = `${runesToUpgrade?.[0]?.chain ?? ''} ${
          runesToUpgrade?.[1]?.chain ?? ''
        }`
        return `Click to upgrade ${oldRuneChain} RUNE to Native RUNE.`
      }
    }

    return null
  }, [wallet])
  const handleUpgrade = useCallback(() => {
    history.push(UPGRADE_RUNE_ROUTE)
  }, [history])

  const isTxPage = useMemo(() => {
    const { pathname } = window.location

    return (
      pathname.includes(SWAP_ROUTE) ||
      pathname.includes(ADD_LIQUIDITY_ROUTE) ||
      pathname.includes(WITHDRAW_ROUTE) ||
      pathname.includes(LIQUIDITY_ROUTE)
    )
  }, [])

  return (
    <Scrollbars
      style={{ height: '100vh' }}
      autoHide
      renderThumbVertical={({ style, ...scrollProps }) => (
        <div
          className="box"
          style={{ ...style, backgroundColor: '#d6cf65', width: '4px' }}
          {...scrollProps}
        />
      )}
    >
      <Styled.LayoutWrapper>
        {showAnnouncement && (
          <Alert
            message="Crosschain.Quest is in BETA, Take your own Risk. Always back up your wallet and do not play with large funds. Bookmark https://crosschain.quest to be safe."
            type="warning"
            showIcon
            closable
            onClose={() => setReadStatus(true)}
          />
        )}
        <Header />
        <Styled.ContentWrapper transparent={transparent}>
          {isTxPage && oldRune && (
            <Styled.NotifyWrapper>
              <Styled.Notify onClick={handleUpgrade}>{oldRune}</Styled.Notify>
            </Styled.NotifyWrapper>
          )}
          {children}
        </Styled.ContentWrapper>
        <WalletModal />
      </Styled.LayoutWrapper>
    </Scrollbars>
  )
}
