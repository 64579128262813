import React from 'react'

import { Link } from 'react-router-dom'

import { ThemeType } from 'theme'

import { useApp } from 'redux/app/hooks'

import { ReactComponent as AffiliateDark } from 'assets/SVG/affiliate-dark.svg'
import { ReactComponent as AffiliateLight } from 'assets/SVG/affiliate-light.svg'

import { IconButton } from '../UIElements'
import { A, NavLabel } from './Link.style'

export const ExternalLink = ({
  link,
  children,
}: {
  link: string
  children: React.ReactChild
}) => (
  <A href={link} target="_blank" rel="noopener noreferrer">
    {children}
  </A>
)

export const ExternalButtonLink = ({
  link,
  children,
  ...others
}: {
  link: string
  children: React.ReactChild
}) => (
  <A href={link} target="_blank" rel="noopener noreferrer" {...others}>
    <IconButton>{children}</IconButton>
  </A>
)

export const ButtonLink = ({
  to,
  children,
}: {
  to: string
  children: React.ReactChild
}) => (
  <Link to={to}>
    <IconButton>{children}</IconButton>
  </Link>
)

export const NavLink = ({
  to,
  active = false,
  children,
}: {
  to: string
  active?: boolean
  children: React.ReactChild
}) => {
  const { themeType } = useApp()

  return (
    <Link to={to}>
      {themeType === ThemeType.DARK ? <AffiliateLight /> : <AffiliateDark />}
      <NavLabel size="normal" weight={active ? 'bold' : '500'}>
        {children}
      </NavLabel>
    </Link>
  )
}
