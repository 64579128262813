import { Tag } from 'antd'
import styled, { keyframes } from 'styled-components'
import { palette } from 'styled-theme'

import { media } from 'helpers/style'

export const slideIn = keyframes`
from {
  text-align: right;
}
to {
    text-align: center;
}
`

export const Section = styled.div`
  //will-change: transform, box-shadow, z-index;
  width: 100%;
  margin: auto;
  justify-content: space-around;
  padding-top: 24px;
  text-align: center;
  color: ${palette('text', 0)};
  /* 
animation-duration: 5s;
animation-name: ${slideIn};
animation-delay: 250ms;
animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);    
animation-iteration-count: infinite; 
*/
  overflow: hidden;
`

/* transparency is 88 */
export const colors: { [key: string]: string } = {
  thorchain: '#23DCC888',
  chainflip: '#ff3cab88',
  sifchain: '#caa93a88',
  anyswap: '#8279ff88',
}

export const ProtocolTag = styled(Tag)`
  background-color: transparent;
  color: ${palette('text', 0)};
  margin-right: 6.75px;
  margin-left: 6.75px;
  width: 100%;
  /* padding-left: 15px; 
  padding-right: 15px; */
  cursor: pointer;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 1rem;
  border-radius: 18px;
  justify-content: space-around;
  text-align: center;
  border: 1px solid;
  ${media.sm`
    font-size: min(3vw, 1rem);
  `}
`
