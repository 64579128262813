import { transparentize } from 'polished'
import styled from 'styled-components'
import { palette } from 'styled-theme'

import { media } from 'helpers/style'

import { WalletButton } from '../UIElements'

export const HeaderContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  height: 70px;

  background-color: transparent;
  background-repeat: no-repeat;
  background: ${({ theme }) =>
    `${transparentize(0.4, theme.palette.background[5])}`};

  padding: 0 10px 6px 10px;
  z-index: 999;
`

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  margin-left: min(1.2vw, 15px);
  margin-right: 10px;

  /* ${media.xs`
    margin: 0 10px;
  `}

  ${media.sm`
    margin: 0 20px;
  `} */
`

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: right;
  border: 4px solid blue;
  text-align: right;
`

export const HeaderAction = styled.div`
  display: flex;
  flex-direction: row;
  //justify-content: space-between;
  justify-content: flex-end;
  margin-right: 1.4rem;
`

export const HeaderCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px;

  border: 1px solid ${palette('gray', 0)};
  border-radius: 0px;

  display: none;
  ${media.sm`
      display: flex;
  `}
`

export const RunePrice = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 6px;

  border: 1px solid ${palette('primary', 0)};
  border-radius: 16px;

  display: none;
  ${media.sm`
      display: flex;
  `}

  margin-left: 0px;
  ${media.sm`
    margin-left: 8px;
  `}
`
export const WebsiteName = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: min(calc(2.1vw + 1vh), 30px);

  background-color: transparent;
  color: ${palette('text', 0)};
`

export const WalletBtn = styled(WalletButton)`
  //margin-left: 10px;
  margin-left: 10px;
  height: 32px;
  margin-top: 1px;
  //margin-right: 30px;
`

export const ToolWrapper = styled.div`
  margin-right: 2px;
  margin-left: 5px;
  display: none;
  ${media.sm`
    display: block;
  `}
`

export const LogoWrapper = styled.div`
  padding-top: 4px;
`
