import { lighten, darken } from 'polished'

import {
  palette,
  DARKENED_STRAW,
  YGGDRASIL_GREEN,
  LITTLE_BOY_BLUE,
  FLASH_ORANGE,
  SURTR_RED,
} from './palette'
import { Theme } from './types'

const { primary, dark } = palette

const COL_DARKEN_RATE = 0.15
const COL_LIGHTEN_RATE = 0.35

const DARK_COL = darken(COL_DARKEN_RATE, DARKENED_STRAW)
const LIGHT_COL = lighten(0.2, DARKENED_STRAW)

const DARK_COL_BASE = darken(COL_DARKEN_RATE, LITTLE_BOY_BLUE)
const LIGHT_COL_BASE = lighten(0.2, LITTLE_BOY_BLUE)

const theme: Theme = {
  palette: {
    gradient: [
      `linear-gradient(9.34deg, ${LITTLE_BOY_BLUE} 19.28%, ${DARKENED_STRAW} 106.03%)`, // 0: Default
      `linear-gradient(9.34deg, ${DARK_COL_BASE} 19.28%, ${DARK_COL} 106.03%)`, // darken col
      `linear-gradient(9.34deg, ${LIGHT_COL_BASE} 19.28%, ${LIGHT_COL} 106.03%)`, // lighten col
    ],
    primary: [
      LITTLE_BOY_BLUE, // 0 primary
      YGGDRASIL_GREEN, // 1 primary
    ],
    secondary: [
      DARKENED_STRAW, // 0 secondary
      '#eeeeee', // 1 box-shadow, hover
    ],
    warning: [
      FLASH_ORANGE, // 0: Warning
      darken(COL_DARKEN_RATE, FLASH_ORANGE), // darken col
      lighten(COL_LIGHTEN_RATE, FLASH_ORANGE), // lighten col
      `linear-gradient(47.73deg, ${FLASH_ORANGE} 0%, ${FLASH_ORANGE} 100%)`, // gradient
    ],
    success: [
      LITTLE_BOY_BLUE, // 0: Success
      darken(COL_DARKEN_RATE, LITTLE_BOY_BLUE), // darken col
      lighten(COL_LIGHTEN_RATE, LITTLE_BOY_BLUE), // lighten col
      `linear-gradient(47.73deg, ${LITTLE_BOY_BLUE} 0%, ${LITTLE_BOY_BLUE} 100%)`, // gradient
    ],
    error: [
      SURTR_RED, // 0: Error
      darken(COL_DARKEN_RATE, SURTR_RED), // darken col
      lighten(COL_LIGHTEN_RATE, SURTR_RED), // lighten col
      `linear-gradient(47.73deg, ${SURTR_RED} 0%, ${SURTR_RED} 100%)`, // gradient
    ],
    gray: [
      dark[0], // 0: Border
      DARKENED_STRAW, // 1: step bar, txstatus bg
      dark[2], // 2: hover
    ],
    background: [
      lighten(0.32, primary[0]), // 0: header, footer bg
      '#cccccc', // 1: main bg (top half)
      '#eeeeee', // 2: hover
      '#eeeeee', // 3: main bg (bottom half)
      '#ffffff', // 4: popover bg
      '#ffffff', // 5: panel
      primary[1], // 6: card (left)
      primary[2], // 7: card (right)
      primary[0], // 8: swap button
    ],
    text: [
      '#25283E', // 0: Normal Text (normal)
      '#54566F', // 1: Active (dark)
      dark[8], // 2: light text
      '#fff', // 3: white text
      primary[0], // 4: primary (blue) text
      primary[4], // 5: dark gold text
    ],
  },
  sizes: {
    headerHeight: '70px',
    footerHeight: '50px',
    panelHeight: '550px',
    panelHeaderHeight: '50px',
    lineHeight: '50px',
    crypto: '35px',
    icon: '16px',
    social: '17px',
    gutter: {
      horizontal: '30px',
      vertical: '20px',
    },
    button: {
      small: {
        width: '55px',
        height: '20px',
      },
      normal: {
        width: '100px',
        height: '30px',
      },
      big: {
        width: '166px',
        height: '70px',
      },
    },
    tooltip: {
      small: '15px',
      normal: '30px',
    },
    font: {
      tiny: '8px',
      small: '10px',
      normal: '12px',
      big: '15px',
      large: '18px',
    },
    coin: {
      small: '30px',
      big: '40px',
    },
  },
  fonts: {
    primary: 'Exo 2',
    pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
  },
}

export default theme
