import React, { useMemo } from 'react'

import { Amount, Asset, Price } from 'multichain-sdk'

import * as Styled from './AssetData.style'

/**
 * AssetData - Component to show asset data in one row:
 *
 * |------|-------------------|-------------------|------------------|
 * | icon | ticker (optional) | amount (optional) | price (optional) |
 *          chain
 * |------|-------------------|-------------------|------------------|
 *
 */

export type Props = {
  asset: Asset
  amount?: Amount
  price?: Price
  size?: Styled.AssetDataSize
  labelSize?: 'small' | 'normal' | 'big' | 'large'
  showLabel?: boolean
  decimal?: number
  full?: boolean
}

export const AssetData: React.FC<Props> = (props): JSX.Element => {
  const {
    asset,
    amount,
    price,
    size = 'normal',
    labelSize = 'big',
    showLabel = true,
    decimal = 6,
    full = false,
    ...others
  } = props

  const labelSizeValue = useMemo(() => {
    if (labelSize) return labelSize

    if (size === 'big') return 'large'
    return 'big'
  }, [size, labelSize])

  const amountSizeValue = useMemo(() => {
    if (labelSize === 'large') return 'big'
    if (labelSize === 'big') return 'normal'
    return labelSize
  }, [labelSize])

  return (
    <Styled.Wrapper {...others} full={full}>
      <Styled.AssetInfo>
        <Styled.AssetIcon asset={asset} size={size} />
        {showLabel && (
          <div className="asset-symbol">
            <Styled.TickerRow>
              <Styled.TickerLabel size={labelSizeValue}>
                {asset.ticker}
              </Styled.TickerLabel>
              <Styled.TypeLabel color={asset.isSynth ? 'primary' : 'gray'}>
                {asset.type}
              </Styled.TypeLabel>
            </Styled.TickerRow>
          </div>
        )}
      </Styled.AssetInfo>
      {!!amount && (
        <div className="asset-extra-label">
          <Styled.AmountLabel size={amountSizeValue}>
            {amount.toSignificant(decimal)}
          </Styled.AmountLabel>
        </div>
      )}
      {!!price && (
        <div>
          <Styled.PriceLabel size={amountSizeValue}>
            {price.toFixed(2)}
          </Styled.PriceLabel>
        </div>
      )}
    </Styled.Wrapper>
  )
}
