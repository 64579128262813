import React, { useCallback, useMemo, useState } from 'react'

import { Scrollbars } from 'react-custom-scrollbars'

import { SearchOutlined } from '@ant-design/icons'

import { Input } from '../Input'
import { ListWrapper, ListItem, List } from './FilterList.style'

type Props<T> = {
  data: T[]
  placeholder?: string
  searchEnabled?: boolean
  cellRenderer: (data: T) => { key: string; node: JSX.Element }
  disableItemFilter?: (item: T) => boolean
  filterFunction: (item: T, searchTerm: string) => boolean
  onSelect?: (value: string) => void
}

export const FilterList = <T extends unknown>(props: Props<T>): JSX.Element => {
  const {
    onSelect = () => {},
    searchEnabled = false,
    data,
    filterFunction,
    cellRenderer,
    disableItemFilter = () => false,
    placeholder = 'Search',
  } = props

  const [searchTerm, setSearchTerm] = useState('')

  const handleClick = useCallback(
    (key) => {
      setSearchTerm('')
      onSelect(key)
    },
    [onSelect],
  )

  const handleSearchChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchTerm = event.currentTarget.value
      setSearchTerm(newSearchTerm)
    },
    [],
  )

  const filteredData: T[] = useMemo(
    () =>
      searchTerm === ''
        ? data
        : data.filter((item) => filterFunction(item, searchTerm)),
    [data, filterFunction, searchTerm],
  )

  const handleEnter = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        const item = filteredData.values().next().value
        if (item) {
          const { key } = cellRenderer(item)
          setSearchTerm('')
          onSelect(key)
        }
      }
    },
    [filteredData, onSelect, cellRenderer],
  )

  return (
    <ListWrapper>
      {searchEnabled && (
        <Input
          autoFocus
          value={searchTerm}
          onChange={handleSearchChanged}
          onKeyPress={handleEnter}
          placeholder={placeholder}
          sizevalue="big"
          typevalue="ghost"
          suffix={<SearchOutlined />}
        />
      )}
      <List>
        <Scrollbars
          height={340}
          autoHide
          renderThumbVertical={({ style, ...scrollProps }) => (
            <div
              className="box"
              style={{ ...style, backgroundColor: '#d6cf65', width: '4px' }}
              {...scrollProps}
            />
          )}
        >
          {filteredData.map((item: T) => {
            const { key, node } = cellRenderer(item)
            const disableItem = disableItemFilter(item)

            return (
              <ListItem
                onClick={() => {
                  if (!disableItem) handleClick(key)
                }}
                disabled={disableItem}
                key={key}
              >
                {node}
              </ListItem>
            )
          })}
        </Scrollbars>
      </List>
    </ListWrapper>
  )
}

export default FilterList
