import React, { useState, useMemo, useRef } from 'react'

import { useLocation } from 'react-router-dom'

import { InfoCircleOutlined } from '@ant-design/icons'
import { ThemeType } from 'theme'

import { NavLabel } from 'components/Link/Link.style'

import { useApp } from 'redux/app/hooks'

import { useMedia } from 'hooks/useMedia'
import { useOnClickOutside } from 'hooks/useOnClickOutside'

import { ReactComponent as DiscordDark } from 'assets/SVG/discord-dark2.svg'
import { ReactComponent as DiscordLight } from 'assets/SVG/discord-light2.svg'

import { NavLink } from '../Link'
import { navMenuList } from './data'
import * as Styled from './Navbar.style'

export const Navbar = () => {
  const { pathname } = useLocation()
  const { themeType } = useApp()
  const isMediaLg = useMedia('(min-width: 1238px)')

  const [isMenuOpen, setMenuOpen] = useState(false)
  const navRef = useRef(null)

  useOnClickOutside(navRef, () => {
    if (navRef && isMenuOpen === true) {
      setMenuOpen(false)
    }
  })

  // const handleOpen = React.useCallback(() => {
  //   setOpen(true)
  // }, [])

  // const handleClose = React.useCallback(() => {
  //   setOpen(false)
  // }, [])

  const toggleMenuOpen = React.useCallback(() => {
    if (isMenuOpen) setMenuOpen(false)
    else setMenuOpen(true)
  }, [isMenuOpen])

  const renderMenu = useMemo(() => {
    return navMenuList
      .filter(({ isWideOnly }) => !isWideOnly || isMediaLg)
      .map(({ link, label }) => (
        <NavLink to={link} key={link} active={pathname === link}>
          {label}
        </NavLink>
      ))
  }, [pathname, isMediaLg])

  return (
    <nav ref={navRef}>
      <Styled.Container collapsed={false}>
        <Styled.Header>
          <Styled.Options onClick={toggleMenuOpen} round>
            <span>. . .</span>
          </Styled.Options>
        </Styled.Header>
        {isMenuOpen && (
          <Styled.MobileNavbar onClick={toggleMenuOpen}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://crosschain.quest/"
            >
              <InfoCircleOutlined />
              <NavLabel size="normal" weight="500">
                ABOUT
              </NavLabel>
            </a>
            {renderMenu}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://discord.gg/fAf2ykVJSF"
            >
              {themeType === ThemeType.DARK ? (
                <DiscordLight />
              ) : (
                <DiscordDark />
              )}
              <NavLabel size="normal" weight="500">
                DISCORD
              </NavLabel>
            </a>
          </Styled.MobileNavbar>
        )}
      </Styled.Container>
    </nav>
  )
}
