import axios from 'axios'

import {
  MIDGARD_CHAOSNET_URL,
  MIDGARD_TESTNET_URL,
  THORNODE_MAINNET_URL,
  THORNODE_TESTNET_URL,
} from './config'

export const getThornodeBaseUrl = (network: 'mainnet' | 'testnet') => {
  if (network === 'mainnet') {
    return THORNODE_MAINNET_URL
  }
  return THORNODE_TESTNET_URL
}

export const getMidgardBaseUrl = (network: 'mainnet' | 'testnet') => {
  if (network === 'mainnet') {
    return MIDGARD_CHAOSNET_URL
  }
  return MIDGARD_TESTNET_URL
}

// https://thornode.thorchain.info/thorchain/inbound_addresses
export const getThornodeInboundAddress = async (
  network: 'mainnet' | 'testnet',
) => {
  const baseUrl = getMidgardBaseUrl(network)

  const response = await axios.get(`${baseUrl}/v2/thorchain/inbound_addresses`)

  return response
}
